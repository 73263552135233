import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { User } from '@app/_models';
import { AuthenticationService } from '@app/_services';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.less']
})
export class MenuComponent implements OnInit {

  user: User;

  @Output() onToggleClick: EventEmitter<any> = new EventEmitter<any>();
  constructor(private authenticationService: AuthenticationService) {}

  ngOnInit(): void {
    this.authenticationService.user.subscribe(x => this.user = x);
  }
  clickEvent(){
    this.onToggleClick.emit();   
  }

  logout() {
    this.authenticationService.logout();
  }

}

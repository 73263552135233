import { NgModule, APP_INITIALIZER } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
//import { LocationStrategy, HashLocationStrategy } from '@angular/common';//activa el hash en la ruta
import { AppRoutingModule } from './app-routing.module';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';



import { AppComponent } from './app.component';
import { JwtInterceptor, ErrorInterceptor, appInitializer } from './_helpers';
import { AuthenticationService } from './_services';
import { HomeComponent } from './home';
import { LoginComponent } from './login';
import { DashboardComponent } from './dashboard/dashboard.component';
import { RotariosComponent } from './rotarios/rotarios.component'
import { ProductService } from './_services/product.service';
import { MenuComponent } from './shared/menu/menu.component';
import { PrimengModule } from './primeng/primeng.module';
import { SidebarComponent } from './shared/sidebar/sidebar.component';
import { JugadoresModule } from './jugadores/jugadores.module';

@NgModule({
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    AppRoutingModule,
    
    BrowserAnimationsModule,
    PrimengModule,
    JugadoresModule
    ],
    declarations: [
        AppComponent,
        HomeComponent,
        LoginComponent,
        DashboardComponent,
        RotariosComponent,
        MenuComponent,
        SidebarComponent

    ],
    providers: [
      { provide: APP_INITIALIZER, useFactory: appInitializer, multi: true, deps: [AuthenticationService] },
      { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
      { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
      //{ provide: LocationStrategy, useClass: HashLocationStrategy }, //activa el hash en la ruta
      ProductService      
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }

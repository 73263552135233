import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ConfirmationService, MessageService } from 'primeng/api';

import { JugadoresRoutingModule } from './jugadores-routing.module';
import { JugadoresComponent } from './pages/jugadores/jugadores.component';
import { PrimengModule } from '@app/primeng/primeng.module';
import { PagosComponent } from './components/pagos/pagos.component';


@NgModule({
  declarations: [
    JugadoresComponent,
    PagosComponent
  ],
  imports: [
    CommonModule,
    JugadoresRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    PrimengModule
  ],
  exports: [PagosComponent],
  providers: [MessageService, ConfirmationService]
})
export class JugadoresModule { }

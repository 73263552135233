import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';


import { HomeComponent } from './home';
import { LoginComponent } from './login';
import { RotariosComponent } from './rotarios/rotarios.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { AuthGuard } from './_helpers';

const routes: Routes = [
    { path: '', component: HomeComponent },
    { path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuard]},
    { path: 'rotarios', component: RotariosComponent, canActivate: [AuthGuard] },
    { path: 'patrocinadores',
        loadChildren: () => import(`./patrocinadores/patrocinadores.module`).then(
          module => module.PatrocinadoresModule
        ), canActivate: [AuthGuard] 
    },
    { path: 'jugadores',
        loadChildren: () => import(`./jugadores/jugadores.module`).then(
          module => module.JugadoresModule
        ), canActivate: [AuthGuard] 
    },
    { path: 'administracion',
        loadChildren: () => import(`./administracion/administracion.module`).then(
          module => module.AdministracionModule
        ), canActivate: [AuthGuard] 
    }, 
    
    { path: 'login', component: LoginComponent },


    // otherwise redirect to home
    { path: '**', redirectTo: '' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
    exports: [RouterModule]
})
export class AppRoutingModule { }

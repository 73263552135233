import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environments/environment';
import { Contador } from '../_models/contador';
import { DashboardPatrocinadorTipo } from '../_models/dashboardPatrocinadorTipo';


@Injectable({ providedIn: 'root' })
export class DashboardService {
    constructor(private http: HttpClient) { }

  getAll() {
    return this.http.get<Contador[]>(`${environment.apiUrl}/api/dashboard`);
  }

  getPatrocinadores() {
    return this.http.get<DashboardPatrocinadorTipo[]>(`${environment.apiUrl}/api/dashboard/patrocinadorestipo`);
  }
}

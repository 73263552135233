<h1 class="h4">Rotarios 
  <small class="fs-6 text-secondary"> - administración de rotarios</small>
</h1>
<p-toast></p-toast>

 <p-table #dt [value]="rotarios" [rows]="10" [paginator]="true"
           [globalFilterFields]="['nombre','telefono','correo']"
           responsiveLayout="scroll"
           [(selection)]="selectedRotarios" [rowHover]="true" dataKey="id"
           currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
           [showCurrentPageReport]="true">
    <ng-template pTemplate="caption">
      <p-toolbar>
        <ng-template pTemplate="left">
          <button pButton pRipple label="Nuevo" icon="pi pi-plus" class="p-button-success p-mr-2" (click)="openNew()"></button>
          <!-- <button pButton pRipple [label]="Delete" icon="pi pi-trash" class="p-button-danger" (click)="deleteselectedRotarios()" [disabled]="!selectedRotarios || !selectedRotarios.length"></button> -->
        </ng-template>    
        <ng-template pTemplate="right">                  
          <span class="p-input-icon-left">
            <i class="pi pi-search"></i>
            <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')" placeholder="Search..." />
          </span>
        </ng-template>
      </p-toolbar>      
    </ng-template>
    <ng-template pTemplate="header">
      <tr>
        <th style="width: 3rem">
          <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
        </th>
        <th pSortableColumn="rotarioID">Clave<p-sortIcon field="rotarioId"></p-sortIcon></th>
        <th pSortableColumn="nombre">Nombre<p-sortIcon field="nombre"></p-sortIcon></th>
        <th pSortableColumn="telefono">Teléfono <p-sortIcon field="telefono"></p-sortIcon></th>
        <th pSortableColumn="correo">Email<p-sortIcon field="correo"></p-sortIcon></th>
        <th></th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-rotario>
      <tr>
        <td>
          <p-tableCheckbox [value]="rotario"></p-tableCheckbox>
        </td>
        <td>{{rotario.rotarioId}}</td>
        <td>{{rotario.nombre}} {{rotario.apellidos}}</td>
        <td>{{rotario.telefono}}</td>
        <td>{{rotario.correo}}</td>
        <td>
          <button pButton pRipple icon="pi pi-pencil" class="p-button-rounded p-button-success p-mr-2" (click)="edit(rotario)"></button>
          <button pButton pRipple icon="pi pi-trash" class="p-button-rounded p-button-warning" (click)="delete(rotario)"></button>
        </td>
      </tr>
    </ng-template>
    <ng-template pTemplate="summary">
      <div class="p-d-flex p-ai-center p-jc-between">
        En total hay  {{rotarios ? rotarios.length : 0 }} rotarios.
      </div>
    </ng-template>
  </p-table>


<p-dialog [(visible)]="productDialog" [style]="{width: '500px'}" class="p-fluid" header="Rotario" [modal]="true">
  <ng-template pTemplate="content">

    <div class="p-field">
      <label for="nombre">Nombre</label>
      <input type="text" pInputText id="nombre" [(ngModel)]="rotario.nombre" required autofocus />
      <small class="p-error" *ngIf="submitted && !rotario.nombre">El nombre es requerido.</small>
    </div>

    <div class="p-field">
      <label for="apellidos">Apellidos</label>
      <input type="text" pInputText id="apellidos" [(ngModel)]="rotario.apellidos" required />
      <small class="p-error" *ngIf="submitted && !rotario.apellidos">El Apellido es requerido.</small>
    </div>
   
    <div class="p-field">
      <label for="telefono">Teléfono</label>
      <p-inputMask id="telefono" mask="(999) 999-9999" [(ngModel)]="rotario.telefono"></p-inputMask>
      <small class="p-error" *ngIf="submitted && !rotario.telefono">El teléfono es requerido.</small>
    </div>

    <div class="p-field">
      <label for="correo">Correo</label>
      <input type="email" pInputText id="correo" [(ngModel)]="rotario.correo" />
    </div>




  </ng-template>

  <ng-template pTemplate="footer">    
    <button pButton pRipple label="Cancelar" icon="pi pi-times" class="p-button-text" (click)="hideDialog()"></button>
    <button pButton pRipple label="Guardar" icon="pi pi-check" class="p-button-text" (click)="save()"></button>
  </ng-template>

</p-dialog>

<p-confirmDialog [style]="{width: '450px'}"></p-confirmDialog>

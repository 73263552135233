<div class="d-flex" id="wrapper">

  <!-- /#sidebar-wrapper -->
  <app-sidebar *ngIf="user" 
  [(status)]="statusSideMenu"></app-sidebar>
  <!-- Page Content -->
  <div id="page-content-wrapper">
     <app-menu (onToggleClick)="clickEvent()"></app-menu>     
    <div class="container-fluid p-4">
      <router-outlet></router-outlet>
    </div>
  </div>
  <!-- /#page-content-wrapper -->
</div>
<!-- /#wrapper -->

import { Component, Input, OnInit } from '@angular/core';

import {  trigger, state, transition, animate, keyframes, style } from "@angular/animations";

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css'],
  animations: [
      
    trigger('toogle', [
      state('close', style({ height: '0px', opacity:'0'})),
      state('open', style({ height: '*', opacity:'*' })),
      transition('close <=> open', animate('400ms ease-in'))

    ])

  ]
})
export class SidebarComponent implements OnInit {

  constructor() { }
  ngOnInit(): void {
    this.adminHide = true;
  }
  
  @Input() status: boolean = false;

  adminState = 'close';
  adminHide: boolean = false;
  toggleAdmin(){
    this.adminState = this.adminState === 'close'? 'open': 'close';
    this.adminHide = !this.adminHide;
  }



}

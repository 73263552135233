import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environments/environment';
import { PatrocinadorTipo } from '@app/_models';


@Injectable({
  providedIn: 'root'
})
export class PatrocinadorTipoService {
  constructor(private http: HttpClient) { }

  getAll() {
    return this.http.get<PatrocinadorTipo[]>(`${environment.apiUrl}/api/patrocinadortipo`);
  }
  
  create(tipo: PatrocinadorTipo) {
    return this.http.post<PatrocinadorTipo>(`${environment.apiUrl}/api/patrocinadortipo`, tipo);
  }
  update(tipo: PatrocinadorTipo) {
    return this.http.put(`${environment.apiUrl}/api/patrocinadortipo/${tipo.patrocinadorTipoId}`, tipo);
  }
  delete(patrocinadorTipoId: number) {
    return this.http.delete(`${environment.apiUrl}/api/patrocinadortipo/${patrocinadorTipoId}`);
  }
}

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { Jugador } from '../../../_models/jugador';
import { JugadorPago } from '../../../_models/jugadorPago';
import { AuthenticationService } from '../../../_services';
import { JugadorService } from '../../../_services/jugador.service';

@Component({
  selector: 'app-pagos',
  templateUrl: './pagos.component.html',
  styleUrls: ['./pagos.component.css']
})
export class PagosComponent implements OnInit {
  @Input() jugadorId: string;
  @Input() saldo: number;
  @Output() onPagosChanged = new EventEmitter<JugadorPago>();

  pagos: JugadorPago[];
  pago: JugadorPago;
  submitted: boolean;
  constructor(
    private jugadorService: JugadorService,
    private authService: AuthenticationService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService) { }
  public get pagoEmpty(): JugadorPago {
    return {
      jugadorPagoId: 0,
      jugadorId: this.jugadorId,
      fecha: new Date(),
      importe: 0,
      fechaCreacion: new Date(),
      creadoPor: '',
      eliminado: false,
      eliminadoPor: null,
      fechaEliminado: null,
      referencia: null
    }
  }
  ngOnInit(): void {
    this.pago = { ...this.pagoEmpty };
    this.jugadorService.getAllPagos(this.jugadorId).subscribe(
      result => {
        this.pagos = result.filter(p => p.eliminado === false);
      },
      error => {
        this.pagos = [];
        this.messageService.add({ severity: 'error', summary: '¡Error!', detail: 'Error al obtener los pagos del jugador', life: 3000 });

      }
    );
  }
  agregarPago()
  {
    this.submitted = true;
    if (this.pago.importe > 0 && (this.pago.importe <= this.saldo))
    {
      let p = { ...this.pago };
      p.jugadorId = this.jugadorId;
      this.jugadorService.createPago(p).subscribe(
        r => {
          p = r;
          this.pagos.push(p);
          this.messageService.add({ severity: 'success', summary: '¡Listo!', detail: 'Registro agregado', life: 3000 });
          this.onPagosChanged.emit(p);
        },
        e => {
          this.messageService.add({ severity: 'error', summary: '¡Error!', detail: 'No se agregó el registro', life: 3000 });
        });

      this.pago = { ...this.pagoEmpty }
      this.submitted = false;
    }
    else {
      this.messageService.add({ severity: 'warn', summary: '¡Aviso!', detail: 'El importe debe ser mayor a 0 y no debe exceder el saldo pendiente', life: 3000 });
    }
  }
  eliminarPago(pago: JugadorPago) {
    this.confirmationService.confirm({
      message: `Deseas eliminar el pago de ${pago.importe}`,
      header: 'Confirm',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        pago.eliminado = true;
        this.jugadorService.updatePago(pago).subscribe(
          (result) => {
            this.pagos = this.pagos.filter(val => val.jugadorPagoId !== pago.jugadorPagoId);
            this.messageService.add({ severity: 'success', summary: '¡Listo!', detail: 'Registro de pago eliminado', life: 3000 });
            this.onPagosChanged.emit(pago);
          },
          (err) => {
            this.messageService.add({ severity: 'error', summary: '¡Error!', detail: 'No se pudo eliminar el pago', life: 3000 });
          }
        );
      }
    });
  }
}

<style type="text/css">
  .p-inputnumber-input {
    text-align: right !important;
  }

  .text-disponible {
    color: forestgreen !important;
  }
</style>
<h1 class="h4">
  Jugadores
  <small class="fs-6 text-secondary"> - listado de jugadores registrados</small>
</h1>
<!--<p-progressSpinner></p-progressSpinner>-->
<p-toast></p-toast>
<!-- Grid de jugadores -->

<p-table #dt [value]="jugadores" [rows]="10"
         [paginator]="true"
         [resizableColumns]="true"
         [loading]="loading"
         responsiveLayout="scroll"
         [rowHover]="true"
         dataKey="id"
         currentPageReportTemplate="Muestra {first} al {last} de {totalRecords} jugadores"
         [showCurrentPageReport]="true"
         styleClass="p-datatable-gridlines"
         [globalFilterFields]="['nombre','apellidos','correo','telefono','equipo']"
         sortField="fechaCreacion" sortOrder="-1">
  <ng-template pTemplate="caption">
    <p-toolbar>
      <ng-template pTemplate="left">
        <button pButton pRipple label="Nuevo" (click)="openNew()" icon="pi pi-plus" class="p-button-success p-mr-2" style="margin-right: 8px"></button>
      </ng-template>
      <ng-template pTemplate="right">
        <span class="p-input-icon-left p-mr-2">
          <i class="pi pi-search"></i>
          <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')" placeholder="Buscar..." />
        </span>
        <!--<button pButton pRipple label="Importar equipos" icon="pi pi-download" (click)="importarEquipos()" class="p-mr-2 p-d-inline-block" style="margin-right: 8px"></button>
        <button pButton pRipple label="Importar jugadores individuales" icon="pi pi-download" (click)="importarJugadores()" class="p-button-help p-mr-2" style="margin-right: 8px"></button>-->
        <button type="button" pButton pRipple icon="pi pi-file-excel" (click)="exportExcel()"   class="p-button-success p-mr-2" pTooltip="XLS" tooltipPosition="bottom" label="Exportar"></button>
      </ng-template>
    </p-toolbar>
  </ng-template>
  <ng-template pTemplate="header">
    <tr>
      <th pSortableColumn="nombre">Nombre <p-sortIcon field="nombre"></p-sortIcon></th>
      <th pSortableColumn="telefono">Teléfono <p-sortIcon field="telefono"></p-sortIcon></th>
      <th pSortableColumn="correo">Correo <p-sortIcon field="correo"></p-sortIcon></th>
      <th pSortableColumn="cuota">Cuota <p-sortIcon field="cuota"></p-sortIcon></th>
      <th pSortableColumn="saldo">Saldo <p-sortIcon field="saldo"></p-sortIcon></th>
      <th pSortableColumn="equipo">Equipo <p-sortIcon field="equipo"></p-sortIcon></th>
      <th pSortableColumn="confirmado">Confirmado <p-sortIcon field="confirmado"></p-sortIcon></th>
      <th></th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-jugador>
    <tr>
      <td>{{ jugador.nombre }}  {{jugador.apellidos }}</td>
      <td>{{ jugador.telefono }}</td>
      <td>{{ jugador.correo }}</td>
      <td>{{ jugador.cuota }}</td>
      <td>{{ jugador.saldo | currency }}</td>
      <td>{{ jugador.equipo}}</td>
      <td>
        <div class="badge" [ngClass]="{'bg-success':jugador.confirmado,'bg-danger':!jugador.confirmado}">
          {{jugador.confirmado?'Confirmado':'No Confirmado'}}
        </div>
      </td>
      <td>
        <button pButton pRipple icon="pi pi-pencil" class="p-button-rounded p-button-success p-mr-2" (click)="edit(jugador)"></button>
        <button pButton pRipple icon="pi pi-dollar" class="p-button-rounded p-button-secondary p-mr-2" (click)="openPago(jugador)"></button>
        <button pButton pRipple icon="pi pi-trash" class="p-button-rounded p-button-warning" (click)="delete(jugador)"></button>
      </td>
    </tr>
  </ng-template>
</p-table>

<!-- Dailogo para edición de Jugadores -->
<p-dialog [(visible)]="jugadorDialog" [style]="{ width:'950px' }" class="p-fluid" [modal]="true">
  <p-header>Jugador</p-header>
  <ng-template pTemplate="content">
    <p-tabView styleClass="tabview-custom">
      <p-tabPanel styleClass="tabview-custom">
        <ng-template pTemplate="header">
          <i class="bi bi-person-circle" style="margin-right: 5px"></i>
          <span>Datos generales</span>
        </ng-template>
        <div class="p-formgrid p-grid">
          <div class="p-field p-col">
            <label for="nombre">Nombre</label>
            <input type="text" pInputText id="nombre" [(ngModel)]="jugador.nombre" required autofocus />
            <small class="p-error" *ngIf="submitted && !jugador.nombre">El nombre es requerido.</small>
          </div>
          <div class="p-field p-col">
            <label for="apellidos">Apellidos</label>
            <input type="text" pInputText id="apellidos" [(ngModel)]="jugador.apellidos" required autofocus />
            <small class="p-error" *ngIf="submitted && !jugador.apellidos">El apellidos es requerido.</small>
          </div>
        </div>
        <div class="p-formgrid p-grid">
          <div class="p-field p-col">
            <label for="telefono">Teléfono</label>
            <p-inputMask id="telefono" mask="(999) 999-9999" [(ngModel)]="jugador.telefono"></p-inputMask>
          </div>
          <div class="p-field p-col">
            <label for="correo">Correo</label>
            <input type="email" id="correo" pInputText [(ngModel)]="jugador.correo" />
          </div>
        </div>
        <div class="p-formgrid p-grid">
          <div class="p-field p-col-6">
            <label for="handicap">Hándicap</label>
            <p-inputNumber [(ngModel)]="jugador.handicap" [showButtons]="true" [minFractionDigits]="1" [maxFractionDigits]="2" [step]="0.5" mode="decimal" locale="en-US" inputId="handicap"></p-inputNumber>
          </div>
          <div class="p-field-checkbox p-col-3" style="margin-top: 25px;">
            <p-checkbox name="requiereRecibo" [(ngModel)]="jugador.requiereRecibo" binary="true" inputId="recibo"></p-checkbox>
            <label for="recibo">Requiere recibo</label>
          </div>
          <div class="p-field-checkbox p-col-3" *ngIf="!insertMode" style="margin-top: 25px;">
            <p-checkbox name="confirmado" [(ngModel)]="jugador.confirmado" binary="true" inputId="confirmado" label="Check in"></p-checkbox>
          </div>
        </div>
      </p-tabPanel>
      <p-tabPanel styleClass="tabview-custom">
        <ng-template pTemplate="header">
          <i class="bi bi-cash-coin" style="margin-right: 5px"></i>
          <span>Cuota</span>
        </ng-template>
        <div class="p-formgrid p-grid">
          <div class="p-field p-col">
            <label for="coutaId">Cuota</label>
            <p-dropdown appendTo="body"  [options]="cuotas" [(ngModel)]="jugador.coutaId" optionLabel="descripcion"
                        placeholder="Seleccionar..." optionValue="cuotaId" [showClear]="false"
                        (onChange)="onChangeCuota(jugador)" [disabled]="!insertMode" required>
            </p-dropdown>
            <small class="p-error" *ngIf="submitted && !jugador.coutaId">La cuota es requerido.</small>
          </div>
          <div class="p-field p-col">
            <label for="precioInscripcion"> $ Precio inscripción</label>
            <p-inputNumber id="precioInscripcion" [min]="0" [(ngModel)]="jugador.precioInscripcion" mode="decimal"
                           [minFractionDigits]="2" locale="en-US"></p-inputNumber>
          </div>
        </div>
        <div class="p-formgrid p-grid" *ngIf="jugador.coutaId === 3">
          <div class="p-field p-col">
            <label for="patrocinadorId">Patrocinador</label>
            <p-dropdown appendTo="body" [options]="patrocinadores" [(ngModel)]="jugador.patrocinadorId" optionLabel="nombre"
                        placeholder="Seleccionar..." optionValue="patrocinadorId" [showClear]="true" required>
              <ng-template let-patrocinador pTemplate="item">
                <div class="equipo-items">
                  {{patrocinador.nombre}}<span class="badge text-disponible"> - Diponibles: {{patrocinador.jugadoresSinCosto - patrocinador.jugadoresPatrocinados}}</span>
                </div>
              </ng-template>
            </p-dropdown>
            <small class="p-error" *ngIf="submitted && !jugador.patrocinadorId">El patrocinador es requerido.</small>
          </div>
        </div>
        <div class="p-formgrid p-grid" *ngIf="(jugador.coutaId && jugador.coutaId != 2) || (jugador.coutaId === 2 && !insertMode)">
          <div class="p-field p-col">
            <label for="equipoId">Equipo</label>
            <p-dropdown appendTo="body" [options]="equipos_disponibles" [(ngModel)]="jugador.equipoId" optionLabel="descripcion"
                        placeholder="Seleccionar..." optionValue="equipoId"
                        [showClear]="true" [disabled]="equipoView" required>
              <ng-template let-equipo pTemplate="item">
                <div class="equipo-items">
                  {{equipo.descripcion}}
                </div>
              </ng-template>
            </p-dropdown>
          </div>
        </div>
        <div class="p-formgrid p-grid" *ngIf="jugador.precioInscripcion>0 && insertMode">
          <div class="p-field-checkbox p-col">
            <p-checkbox name="agregarPago" [(ngModel)]="agregarPago" binary="true" inputId="binary"></p-checkbox>
            <label for="binary">Agregar pago</label>
          </div>
          <div class="p-field p-col-6" *ngIf="agregarPago">
            <label for="precioInscripcion"> $ Pago</label>
            <p-inputNumber id="precioInscripcion" [min]="0" [(ngModel)]="jugador.pago" mode="decimal"
                           [minFractionDigits]="2" locale="en-US" [inputStyle]="{'text-align': 'right'}"></p-inputNumber>
            <small class="p-error" *ngIf="submitted && !jugador.pago">El pago es requerido.</small>
          </div>
        </div>
      </p-tabPanel>
      <p-tabPanel styleClass="tabview-custom" *ngIf="jugador.requiereRecibo">
        <ng-template pTemplate="header">
          <i class="bi bi-receipt" style="margin-right: 5px"></i>
          <span>Recibo</span>
        </ng-template>
        <div class="p-field">
          <label for="reciboNombreFiscal">Nombre Fiscal</label>
          <input type="text" pInputText id="reciboNombreFiscal" [(ngModel)]="jugador.reciboNombreFiscal" required autofocus />
          <small class="p-error" *ngIf="submitted && !jugador.reciboNombreFiscal">El nombre fiscal es requerido.</small>
        </div>
        <div class="p-field">
          <label for="reciboRFC">RFC</label>
          <input type="text" pInputText id="reciboRFC" [(ngModel)]="jugador.reciboRFC" required autofocus />
          <small class="p-error" *ngIf="submitted && !jugador.reciboRFC">El RFC es requerido.</small>
        </div>
        <div class="p-field-checkbox p-col-3" style="margin-top: 25px;">
          <p-checkbox name="reciboEntregado" [(ngModel)]="jugador.reciboEntregado" binary="true" inputId="ckRecibiEntregado"></p-checkbox>
          <label for="reciboEntregado">Recibo entregado</label>
        </div>
      </p-tabPanel>
      <p-tabPanel styleClass="tabview-custom" *ngIf="jugador.coutaId === 2 && insertMode">
        <ng-template pTemplate="header">
          <i class="bi bi-people-fill" style="margin-right: 5px"></i>
          <span>Jugadores</span>
        </ng-template>
        <table class="table table-sm" #tableJugadores>
          <thead>
            <tr>
              <th scope="col" class="text-center" style="width:20%;">Nombre</th>
              <th scope="col" class="text-center" style="width:25%;">Apellido</th>
              <th scope="col" class="text-center" style="width:20%;">Correo</th>
              <th scope="col" class="text-center" style="width:15%;">Teléfono</th>
              <th scope="col" class="text-center" style="width:15%;">Hándicap</th>
              <th scope="col" style="width:5%;"></th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let jugador of jugadoresFoursome" class="animate__animated animate__bounceInDown">
              <td>{{jugador.nombre}}</td>
              <td> {{jugador.apellidos}}</td>
              <td>{{jugador.correo}}</td>
              <td>{{jugador.telefono}}</td>
              <td>{{jugador.handicap | number}}</td>
              <td><button pButton pRipple icon="pi pi-trash" class="p-button-rounded p-button-danger" (click)="eliminarJugadorFoursome(jugador)"></button></td>
            </tr>
            <tr *ngIf="jugadoresFoursome.length <3">
              <td scope="row">
                <input type="text" pInputText placeholder="Nombre" [(ngModel)]="jugadorFoursome.nombre">
                <small class="p-error" *ngIf="submitted && !jugador.nombre">El nombre es obligatorio.</small>
              </td>
              <td>
                <input type="text" pInputText placeholder="Apellidos" [(ngModel)]="jugadorFoursome.apellidos">
                <small class="p-error" *ngIf="submitted && !jugador.apellidos">El apellido es obligatorio.</small>
              </td>
              <td>
                <input type="email" id="correo" pInputText [(ngModel)]="jugadorFoursome.correo" />
              </td>
              <td>
                <p-inputMask mask="(999) 999-9999" placeholder="(999) 999-9999" [(ngModel)]="jugadorFoursome.telefono"></p-inputMask>
              </td>
              <td>
                <p-inputNumber [(ngModel)]="jugadorFoursome.handicap" [showButtons]="true" mode="decimal" inputId="handicap" [step]="0.5" [minFractionDigits]="1" [maxFractionDigits]="2" locale="en-US"></p-inputNumber>
              </td>
              <td>
                <button pButton pRipple icon="pi pi-plus-circle" class="p-button-rounded p-button-success"
                        (click)="saveJugadorFoursome(jugadorFoursome)"></button>
              </td>
            </tr>
          </tbody>
        </table>
      </p-tabPanel>
    </p-tabView>
  </ng-template>
  <ng-template pTemplate="footer">
    <button pButton pRipple label="Cancelar" icon="pi pi-times" class="p-button-secondary" (click)="hideDialog()"></button>
    <button pButton pRipple label="Guardar" icon="pi pi-check" (click)="saveJugador()"></button>
  </ng-template>
</p-dialog>

<!-- Dailogo para edición de Pago de Jugadores -->
<p-dialog [(visible)]="jugadorPagoDialog" [style]="{ width: '600px' }"
          class="p-fluid"
          [modal]="false" [contentStyle]="{'overflow':'visible'}">
  <p-header>
    Pagos del jugador
  </p-header>
  <ng-template pTemplate="content">
    <div class="p-formgrid p-grid">
      <div class="p-field p-col">
        <label for="aportacion">Precio inscripción</label>
        <p-inputNumber [(ngModel)]="jugador.precioInscripcion"
                       [min]="0"
                       mode="decimal"
                       [minFractionDigits]="2"
                       locale="en-US"
                       disabled="true"></p-inputNumber>
      </div>
      <div class="p-field p-col">
        <label for="pagado">Pagado</label>
        <p-inputNumber [(ngModel)]="jugador.pago"
                       [min]="0"
                       mode="decimal"
                       [minFractionDigits]="2"
                       locale="en-US"
                       disabled="true"></p-inputNumber>
      </div>
      <div class="p-field p-col">
        <label for="saldo">Saldo</label>
        <p-inputNumber [(ngModel)]="jugador.saldo"
                       [min]="0"
                       mode="decimal"
                       [minFractionDigits]="2"
                       locale="en-US"
                       disabled="true"></p-inputNumber>
      </div>
    </div>
    <app-pagos [jugadorId]="jugador.jugadorId" [saldo] ="jugador.saldo" (onPagosChanged)="onPagosChanged($event)"></app-pagos>
  </ng-template>
  <ng-template pTemplate="footer">
    <button pButton pRipple label="Cerrar" icon="pi pi-times"  class="p-button-secondary" (click)="hideDialogPago()"></button>
  </ng-template>
</p-dialog>

<p-confirmDialog class="my-confirm-dialog" [style]="{width: '450px'}" baseZIndex="10000" #cd>
  <p-footer>
    <button pButton pRipple label="No" icon="pi pi-times" class="p-button-secondary" (click)="cd.reject()"></button>
    <button pButton pRipple icon="pi pi-check" label="Si" (click)="cd.accept()"></button>
  </p-footer>
</p-confirmDialog>

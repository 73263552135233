import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environments/environment';
import { Equipo } from '@app/_models';


@Injectable({
  providedIn: 'root'
})
export class EquipoService {
  constructor(private http: HttpClient) { }

  getAll() {
    return this.http.get<Equipo[]>(`${environment.apiUrl}/api/equipos`);
  }
  
  create(equipo: Equipo) {
    return this.http.post<Equipo>(`${environment.apiUrl}/api/equipos`, equipo);
  }
  update(equipo: Equipo) {
    return this.http.put(`${environment.apiUrl}/api/equipos/${equipo.equipoId}`, equipo);
  }
  delete(equipoId: number) {
    return this.http.delete(`${environment.apiUrl}/api/equipos/${equipoId}`);
  }

  createEquipoCompleto(equipo: Equipo) {
    return this.http.post<Equipo>(`${environment.apiUrl}/api/equipos/nuevoequipo`, equipo);
  }

  updateEquipo(equipoId: number) {
    return this.http.post<Equipo>(`${environment.apiUrl}/api/equipos/actualizarequipo`,equipoId);
  }
}

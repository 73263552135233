<nav *ngIf="user" class="navbar navbar-expand-lg navbar-light bg-light">
  <div class="container-fluid">
    <button class="btn" (click)="clickEvent()">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="me-auto"></div>
      <div>
        <ul class="navbar-nav mb-lg-0">
          <li class="nav-item px-3 pt-1">
            <i class="bi bi-person-circle"></i>
            &nbsp;<strong>{{user.nombre}}</strong>            
          </li>
          <li class="nav-item">
            <button class="btn btn-primary" (click)="logout()">Cerrar sesión</button>          
          </li>
        </ul>

      </div>
      
    </div>
</nav>
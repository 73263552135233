import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environments/environment';
import { Cuota } from '@app/_models';


@Injectable({
  providedIn: 'root'
})
export class CuotasService {
  constructor(private http: HttpClient) { }

  getAll() {
    return this.http.get<Cuota[]>(`${environment.apiUrl}/api/Cuotas`);
  }
  
  create(cuota: Cuota) {
    return this.http.post<Cuota>(`${environment.apiUrl}/api/Cuotas`, cuota);
  }
  update(couta: Cuota) {
    return this.http.put(`${environment.apiUrl}/api/Cuotas/${couta.cuotaId}`, couta);
  }
  delete(coutaId: number) {
    return this.http.delete(`${environment.apiUrl}/api/Cuotas/${coutaId}`);
  }
}

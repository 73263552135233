import { Component, OnInit, Renderer2 } from '@angular/core';
import { ConfirmationService, MessageService, Message } from 'primeng/api';
import { Cuota, Patrocinador } from '../../../_models';
import { Equipo } from '../../../_models/equipo';
import { Jugador } from '../../../_models/jugador';
import { JugadorPago } from '../../../_models/jugadorPago';
import { CuotasService } from '../../../_services/cuotas.service';
import { EquipoService } from '../../../_services/equipos.service';
import { JugadorService } from '../../../_services/jugador.service';
import { PatrocinadorService } from '../../../_services/patrocinador.service';
import { JugadoresRoutingModule } from '../../jugadores-routing.module';
//const FileSaver = require('file-saver');
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-jugadores',
  templateUrl: './jugadores.component.html',
  styleUrls: ['./jugadores.component.css'],
  styles: [`
        :host ::ng-deep .p-dialog .product-image {
            width: 150px;
            margin: 0 auto 2rem auto;
            display: block;
        }
    `],
  providers: [MessageService, ConfirmationService]
})
export class JugadoresComponent implements OnInit {
  loading = false;
  jugadores: Jugador[];
  jugador: Jugador;
  jugadorPago: JugadorPago;
  jugadorFoursome: Jugador;
  jugadoresFoursome: Jugador[];
  equipos: Equipo[];
  equipos_disponibles: Equipo[];
  equipo: Equipo;
  equipoFoursome: Equipo;
  equipoEmpty: Equipo = {
    equipoId: 0,
    jugadoresActuales: 0,
    fechaRegistro: new Date(),
    descripcion: "",
    completo: false,
    pagado: false,
    referenciaWeb: 0,
    capitanId: null,
    creadoPor: null,
    editadoPor: null,
    fechaCreacion: new Date(),
    fechaEdicion: new Date(),
    fechaEliminado: null,
    eliminado: false,
    jugadores: null,
    hoyoSalida: 0,
    turnoSalida:"",
    confirmados:0
  };
  patrocinadores: Patrocinador[];
  cuotas: Cuota[];
  cuota: Cuota;
  jugadorDialog: boolean;
  jugadorPagoDialog: boolean;
  submitted: boolean;
  msgs: Message[];
  existe_equipo_disponible: boolean;
  agregarPago: boolean;
  insertMode: boolean;
  equipoView: boolean;
  cols: any[];
  constructor(
    private services: JugadorService,
    private messageService: MessageService,
    private patrocinadorService: PatrocinadorService,
    private cuotaService: CuotasService,
    private equiposService: EquipoService,
    private confirmationService: ConfirmationService) { }

  ngOnInit(): void {
    this.reload();
    this.cuotaService.getAll().subscribe(
      (result) => {
        this.cuotas = result
      },
      (err) => {
        this.cuotas = []
        //this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'cuotas Deleted', life: 3000 });

      });
    this.equiposService.getAll().subscribe(
      (result) => {
        
        this.equipos = result;
      },
      (err) => {
        this.equipos = []
      });
  }
  reload() {
    this.jugadores = [];
    this.loading = true;
    this.services.getAll().subscribe(
      (result) => {
        this.jugadores = result
        this.loading = false;
      },
      (err) => {
        this.jugadores = []
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Problema al obtener los registros de jugadores', life: 3000 });
      })
  }
  reloadPatrocinadores() {
    this.patrocinadores = [];
    this.patrocinadorService.getAllPatrocinadores().subscribe(
      (result) => {
        this.patrocinadores = result; 
      },
      (err) => {
        this.patrocinadores = []
      })
  }
  openNew() {
    this.jugador = { ...this.jugadorEmpty };
    this.jugadorFoursome = { ...this.jugadorFoursomeEmpty };
    this.equipoFoursome = { ...this.equipoFoursome };
    this.reloadPatrocinadores();
    this.jugadoresFoursome = [];
    this.submitted = false;
    this.jugadorDialog = true;
    this.insertMode = true;
    this.equipoView = false;
  }
  openPago(jugador: Jugador) {
    this.jugador = { ...jugador };
    this.jugadorPagoDialog = true;
  }
  //----------Importar jugadores y equipos con jugadores------------
  importarEquipos() {
    this.loading = true;
    this.services.importEquipos().subscribe(
      (result) => {   
        var equipos_importados = 0;
        var mensaje = "";
        if (result.length > 0)
        {
          equipos_importados = result.length;
          mensaje = "Se importaron " + equipos_importados + " registros de equipos con jugadores."; 
          this.messageService.add({ severity: 'success', summary: '¡Listo!', detail: mensaje, life: 3000 });
          this.reload();
        }
        else {
          this.messageService.add({ severity: 'success', summary: '¡Listo!', detail: 'Actualmente no existen registros para importar', life: 3000 });
        }

        this.loading = false;
        this.jugadores = [...this.jugadores];
      },
      (err) => {
        this.jugadores = []
        this.loading = false;
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Problema al obtener los registros de jugadores', life: 3000 });
      })
  }
  importarJugadores() {
    this.loading = true;
    this.services.importJugadoresIndividuales().subscribe(
      (result) =>
      {
        if (result.length > 0)
        {
          //Agregar los nuevos jugadores. 
          for (var i = 0; i < result.length; i++)
          {
            this.jugadores.push(result[i]);
          }
          this.loading = false;
          this.messageService.add({ severity: 'success', summary: '¡Listo!', detail: 'Se importaron ' + result.length + "  registros de jugadores", life: 3000 });
        }
        else {
          this.loading = false;
          this.messageService.add({ severity: 'success', summary: '!Listo!', detail: 'Actualmente no existen registros de jugadores individuales por importar', life: 3000 });
        }
        this.jugadores = [...this.jugadores];
      },
      (err) => {
        this.jugadores = []
        this.loading = false;
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Problema al importar los jugadores', life: 3000 });
      })
  }

  //----- Edición del registro de jugador -----------------------
  edit(jugador: Jugador) {
    this.jugador = { ...jugador };
    this.jugadorDialog = true;
    if (jugador.equipoId !== null && jugador.equipoId > 0)
      this.equipoView = true;
    else
      this.equipoView = false;

    this.consultarEquiposDisponibles(jugador.equipoId);

    if (jugador.coutaId === 3) {
      this.reloadPatrocinadores();
      this.consultarPatrocinadoresDisponibles(jugador.patrocinadorId);
    }
  }
  saveJugador()
  {
    this.submitted = true;

    //Validar si es foursome, debe tener sus integrantes agregados
    if (this.insertMode && this.jugador.coutaId == 2 && this.jugadoresFoursome.length < 3) {
      this.messageService.add({ severity: 'warn', summary: '¡Aviso!', detail: 'Para el jugador foursome, se deben dar de alta los jugadores que integraran su equipo', life: 3000 });
      return;
    }
    //Validar si es patrocinador, debe tener seleccionado un patrocinador
    if (this.insertMode &&this.jugador.coutaId === 3 && this.jugador.patrocinadorId == null) {
      this.messageService.add({ severity: 'warn', summary: '¡Aviso!', detail: 'Se debe seleccionar un patrocinador', life: 3000 });
      return;
    }
      

    if (this.jugador.nombre.trim() && this.jugador.coutaId)
    {
      //Editar jugador
      if (this.jugador.jugadorId != "")
      {
        //actualizar saldo
        this.jugador.saldo = this.jugador.precioInscripcion - this.jugador.pago;
        this.jugador.fechaEdicion = new Date();
        
        let jugadorUpdate = { ...this.jugador };

        if (jugadorUpdate.equipoId != null)
          jugadorUpdate.equipo = this.getNombreEquipo(jugadorUpdate.equipoId);

        this.services.update(this.jugador).subscribe(
          (result) => {
            this.jugadores[this.findIndexById(jugadorUpdate.jugadorId)] = jugadorUpdate;
            this.jugadores = [...this.jugadores];
            this.messageService.add({ severity: 'success', summary: '¡Listo!', detail: 'Datos de jugador actualizado', life: 3000 });

            //Se asigno a un equipo: actualizar equipo
            if (jugadorUpdate.equipoId != null)
              this.actualizarEquipo(jugadorUpdate);
          },
          (err) => {
            this.patrocinadores = []
            this.messageService.add({ severity: 'error', summary: '¡Error!', detail: 'Problema al actualizar', life: 3000 });
            console.log(err);
          });
      }
      //Nuevo jugador
      else 
      {
        this.jugador.jugadorId = "00000000-0000-0000-0000-000000000000";
        this.jugador.fechaCreacion = new Date;
        this.jugador.fechaEdicion = new Date;
        this.jugador.saldo = this.jugador.precioInscripcion;

        //Jugador Foursome y equipo agregado
        if (this.jugador.coutaId === 2)
        {
            //Guid unico para capitan 
            this.jugador.jugadorId = "1aaaaaaa-bbbb-cccc-dddd-3eeeeeeeeeee";
            //Crear equipo
            this.crearEquipoFoursome(this.jugador, this.jugadoresFoursome);
        }
        //Jugador individual, patrocinador, foursome integrante
         else if (this.jugador.coutaId !== 2)
        { 
          this.services.createJugador(this.jugador).subscribe(
            (result) => {
              this.jugador = result;
              this.jugadores.push(this.jugador);
              this.jugadores = [...this.jugadores];

              //Se asigno a un equipo: actualizar equipo
              if (this.jugador.equipoId != null)
                this.actualizarEquipo(this.jugador);

              //Se realizó un pago
              if (this.jugador.pago > 0)
                this.agregarJugadorPago();

              this.reload();
              this.messageService.add({ severity: 'success', summary: '¡Muy bien!', detail: 'Jugador creado', life: 3000 });
            },
            (err) => {
              this.messageService.add({ severity: 'error', summary: 'Error al crear el registro de jugador', detail: 'Error al crear', life: 3000 });
            });
        }
      }

      this.jugadorDialog = false;
      this.jugadorPagoDialog = false; 
      this.agregarPago = false;
      this.insertMode = false;
      this.jugador = { ...this.jugadorEmpty };
      this.equipo = { ...this.equipoEmpty };
    }
  }
  delete(jugador: Jugador)
  {
    if (jugador.equipoId > 0) {
      this.messageService.add({ severity: 'warn', summary: '¡Aviso!', detail: 'No se puede eliminar un jugador que es integrante de un equipo', life: 3000 });
      return;
    }
    if (jugador.precioInscripcion > 0 && jugador.pago > 0) {
      this.messageService.add({ severity: 'warn', summary: '¡Aviso!', detail: 'No se puede elimianar un jugador que ya realizo pagos', life: 3000 });
      return; 
    }

    this.confirmationService.confirm({
      message: '¿Deseas eliminar a ' + jugador.nombre + ' ' + jugador.apellidos + '?',
      header: 'Confirm',
      icon: 'pi pi-exclamation-triangle',
      accept: () =>
      {
        jugador.eliminado = true;
        jugador.fechaEliminado = new Date();
        this.services.update(jugador).subscribe((result) =>
        {
          this.jugadores = this.jugadores.filter(val => val.jugadorId !== jugador.jugadorId);
          this.jugador = { ...this.jugadorEmpty };
          this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'Registro eliminado', life: 3000 });
        },
          (err) => {
            this.messageService.add({ severity: 'error', summary: 'Error', detail: 'No se pudo eliminar', life: 3000 });
          }
        );
      }
    });
  }

  //Obtener nombre de equipo
  getNombreEquipo(equipoId: number) {
    let equipo = this.equipos.filter(x => x.equipoId === equipoId);
    if (equipo)
      return equipo[0].descripcion; 
  }

  //Actualizar registro de equipo
  actualizarEquipo(jugador: Jugador)
  {
    if (jugador.equipoId !== null)
    {
      this.equiposService.updateEquipo(jugador.equipoId).subscribe(
        (result) => {
          this.equipo = result;
          this.equipos[this.findEquipoIndexById(jugador.equipoId)] = this.equipo;
          this.equipos = [...this.equipos];
          this.messageService.add({ severity: 'success', summary: '¡Listo!', detail: 'Se actualizó el equipo', life: 3000 });
        },
        (err) => {
          this.messageService.add({ severity: 'error', summary: '¡Error!', detail: 'Problema al actualizar el equipo', life: 3000 });
        }
      );
    }
  }

  //Se obtiene el precio de inscripcion en base a la cuota seleccionada
  onChangeCuota(jugador: Jugador)
  {
    this.existe_equipo_disponible = false;
    //Buscar cuota. 
    this.cuota = this.cuotas.find(x => x.cuotaId === jugador.coutaId);
    if (this.cuota)
    {
      //Asignar precio de inscripcion en base a la cuota seleccionada
      jugador.precioInscripcion = this.cuota.precio;

      if (jugador.coutaId != 2)
      {
        this.consultarEquiposDisponibles(jugador.equipoId);
        this.jugador.patrocinadorId = null;
      }

      if (jugador.coutaId === 3)
        this.consultarPatrocinadoresDisponibles(jugador.patrocinadorId);
    }
  }
 

  //Consultar equipos disponibles para un jugador
  consultarEquiposDisponibles(equipoId: number)
  {
    if (equipoId != null && equipoId > 0)// Tiene un equipo asignado
      this.equipos_disponibles = this.equipos.filter(x => x.equipoId === equipoId || x.jugadoresActuales < 4);
    else
      this.equipos_disponibles = this.equipos.filter(x => x.jugadoresActuales < 4 && x.completo===false);

    if (this.equipos_disponibles.length <= 0)
      this.existe_equipo_disponible = true;
  }

  //Consultar patrocinadores disponibles en base a los espacio de jugadores por patrocinar
  consultarPatrocinadoresDisponibles(patrocinadorId: string)
  {
    console.log('Consultar patrocinadores disponibles')

    // Tiene un patrocinador asignado
    if (patrocinadorId !== null)
    {
      console.log('Tiene patrocinador')
      this.patrocinadores = this.patrocinadores.filter(p => p.patrocinadorId === patrocinadorId || (p.jugadoresSinCosto > p.jugadoresPatrocinados));
      console.log(this.patrocinadores)
    }
    else {
      console.log('Sin patrocinador')
      console.log(this.patrocinadores)
      this.patrocinadores = this.patrocinadores.filter(p => p.jugadoresSinCosto > p.jugadoresPatrocinados);
      
    }
  }

  //--------------- Jugador Pago -----------------------

  //Crear registro de pago del jugador
  agregarJugadorPago() {
    let p = { ...this.jugadorPago };
    //Valores por defecto
    p.jugadorId = this.jugador.jugadorId;
    p.fecha = new Date();
    p.importe = this.jugador.pago;

    this.services.createPago(p).subscribe(
      r => {
        p = r;
        this.messageService.add({ severity: 'success', summary: '¡Listo!', detail: 'Registro de pago agregado', life: 3000 });
      },
      e => {
        this.messageService.add({ severity: 'error', summary: '¡Error!', detail: 'Problema al registar el pago', life: 3000 });
      });
    this.jugadorPago = { ...this.pagoEmpty };
  }

  //Método utilizado para recibir eventos del component de pagos
  onPagosChanged(jugadorPago: JugadorPago)
  {
    //Actualizar datos del jugador que realizaro pago en lista de jugadores.
    this.services.getJugador(jugadorPago.jugadorId).subscribe(
      result => {
        this.jugador = result;
        this.reload();
      },
      error => {
        this.jugadores = [];
        this.messageService.add({ severity: 'error', summary: '¡Error!', detail: 'Problema al obtener el registro del jugador', life: 3000 });

      }
    );
  }

  //------------ Jugador Foursome ------------------------
  crearEquipoFoursome(capitan: Jugador, integrantes: Jugador[])
  {
    //Obtener el consecutivo de equipos
    this.equipoFoursome.equipoId = Math.max.apply(Math, this.equipos.map(function (o) { return o.equipoId; })) + 1;
    //Asignar a los integrantes del equipo un Id y el equipo al que van a pertenecer
    integrantes.forEach(i => {
      i.jugadorId = "00000000-0000-0000-0000-000000000000"
      i.equipoId = this.equipoFoursome.equipoId;
    });
    //Agregar capitan a la lista de jugadores del equipo
    integrantes.push(capitan);
    this.equipoFoursome.capitanId = capitan.jugadorId;
    this.equipoFoursome.completo = true;
    this.equipoFoursome.jugadores = integrantes;
    this.equiposService.createEquipoCompleto(this.equipoFoursome).subscribe(
      (result) => {
        result.jugadores.forEach(jugador =>
        {
          this.jugadores.push(jugador);
          if (jugador.pago > 0)
          {
            this.jugador = jugador;
            this.agregarJugadorPago();
          }
        })
        this.reload();
        this.messageService.add({ severity: 'success', summary: '¡Muy bien!', detail: 'Equipo completo creado', life: 3000 });
      },
      (err) => {
        this.messageService.add({ severity: 'error', summary: 'Error al crear el registro de jugador', detail: 'Error al crear el equipo completo', life: 3000 });
      });

    this.equipoFoursome = { ...this.equipoFoursomeEmpty };
    this.jugadorFoursome = { ...this.jugadorFoursomeEmpty };
  }
  //Agregar integrantes del foursome
  saveJugadorFoursome(jugador: Jugador)
  {
    let size = this.jugadoresFoursome.length;
    if (jugador.nombre.trim())
    {
      console.debug(jugador); 
      jugador.jugadorId = size.toString();
      this.jugadoresFoursome.push(jugador);

      this.jugadorFoursome = { ...this.jugadorFoursomeEmpty };
      this.jugadoresFoursome = [...this.jugadoresFoursome];
    }
  }
  //Eliminar integrantes del foursome
  eliminarJugadorFoursome(jugador: Jugador)
  {
    if (jugador.jugadorId != null) {
      this.jugadoresFoursome = this.jugadoresFoursome.filter(item => item.jugadorId !== jugador.jugadorId);
    }
  }

  exportExcel() {
    let data = this.jugadores.map(function (item) {
      return {
        'Id': item.jugadorId,
        'Apellidos': item.apellidos,
        'Nombre': item.nombre,
        'Foursome': item.equipo,
        'Handicap': item.handicap,        
        'Hoyo de salida': item.equipoHoyoSalida, 
        'Turno de salida': item.equipoTurnoSalida,
        'Telefono' : item.telefono,
        'Correo' : item.correo,
        'Patrocinador' : item.patrocinador,
        'Couta' : item.cuota,        
        'Precio de Inscripcion' : item.precioInscripcion, 
        'Pagado' : item.pago,
        'Saldo' : item.saldo,  
        'Confirmado' : item.confirmado,
        'Requiere recibo': item.requiereRecibo,
        'Nombre fiscal' : item.reciboNombreFiscal,
        'RFC' : item.reciboRFC,
        'Recibo entregado': item.reciboEntregado,        
        'Fecha de registro' : item.fechaRegistro,
        'Referencia Web' : item.referenciaWeb         
      }
    });
    import("xlsx").then(xlsx => {
      const worksheet = xlsx.utils.json_to_sheet(data);
      const workbook = { Sheets: { 'jugadores': worksheet }, SheetNames: ['jugadores'] };
      const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
      this.saveAsExcelFile(excelBuffer, "jugadores");
    });
  }

  saveAsExcelFile(buffer: any, fileName: string): void {
    let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    let EXCEL_EXTENSION = '.xlsx';
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE
    });
    FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
  }
 //------------ Adicionales ------------------------------
  public get jugadorEmpty(): Jugador {
    return {
      jugadorId: '',
      nombre: '',
      apellidos: '',
      telefono: '',
      correo: '',
      coutaId: null,
      cuota: '',
      equipoId: null,
      equipo: '',
      confirmado: false,
      requiereRecibo: false,
      referenciaWeb: null,
      reciboNombreFiscal: '',
      reciboRFC: '',
      precioInscripcion: 0,
      pago: 0,
      saldo: 0,
      imageFilenameQR: '',
      patrocinadorId: null,
      patrocinador: '',
      fechaRegistro: new Date(),
      creadoPor: '',
      editadoPor: '',
      fechaCreacion: new Date(),
      eliminado: false,
      fechaEdicion: new Date(),
      fechaEliminado: null,
      handicap: 0.0,
      equipoHoyoSalida: null, 
      reciboEntregado: false
    }
  }
  public get pagoEmpty(): JugadorPago {
    return {
      jugadorPagoId: 0,
      jugadorId: this.jugador.jugadorId,
      fecha: new Date(),
      importe: 0,
      fechaCreacion: new Date(),
      creadoPor: '',
      eliminado: false,
      eliminadoPor: null,
      fechaEliminado: null,
      referencia: null
    }
  }
  public get jugadorFoursomeEmpty(): Jugador {
    return {
      jugadorId: '',
      nombre: '',
      apellidos: '',
      telefono: '',
      correo: '',
      coutaId: 4,
      cuota: '',
      equipoId: 0,
      equipo: '',
      confirmado: false,
      requiereRecibo: false,
      referenciaWeb: null,
      reciboNombreFiscal: '',
      reciboRFC: '',
      precioInscripcion: 0,
      pago: 0,
      saldo: 0,
      imageFilenameQR: '',
      patrocinadorId: null,
      patrocinador: '',
      fechaRegistro: new Date(),
      creadoPor: '',
      editadoPor: '',
      fechaCreacion: new Date(),
      eliminado: false,
      fechaEdicion: new Date(),
      fechaEliminado: null,
      handicap:0, 
      reciboEntregado:false
    }
  }
  public get equipoFoursomeEmpty(): Equipo {
    return {
      equipoId: 0,
      jugadoresActuales: 0,
      fechaRegistro: new Date(),
      descripcion: "",
      completo: false,
      pagado: false,
      referenciaWeb: 0,
      capitanId: null,
      creadoPor: null,
      editadoPor: null,
      fechaCreacion: new Date(),
      fechaEdicion: new Date(),
      fechaEliminado: null,
      eliminado: false,
      jugadores: null,
      hoyoSalida: 0,
      turnoSalida:"",
      confirmados:0
    }
  }
  hideDialog() {
    this.jugadorDialog = false;
    this.submitted = false;
    this.agregarPago = false;
    this.insertMode = false;
    this.equipos_disponibles = []; 
  }
  hideDialogPago() {
    this.jugadorPagoDialog = false;
  }
//------------- Funciones para buscar -----------------------
  findIndexByIdList(id: string, lista: Jugador[]): number {
    let index = -1;
    for (let i = 0; i < this.jugadores.length; i++) {
      if (lista[i].jugadorId === id) {
        index = i;
        break;
      }
    }
    return index;
  }
  findIndexById(id: string): number {
    let index = -1;
    for (let i = 0; i < this.jugadores.length; i++) {
      if (this.jugadores[i].jugadorId === id) {
        index = i;
        break;
      }
    }
    return index;
  }
  findEquipoIndexById(id: number): number {
    let index = -1;
    for (let i = 0; i < this.equipos.length; i++) {
      if (this.equipos[i].equipoId === id) {
        index = i;
        break;
      }
    }
    return index;
  }
}

<style type="text/css">
  .data-tile {
    font-size: 1rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .contador-number {
    color: #6f777f;
  }
  .p-lg-5 {
    padding: 0.5rem !important;
  }
  .badg-normal {
      font-weight: 950 !important;
  }
</style>

<h1 class="h4">
  Dashboard
  <small class="fs-6 text-secondary"> - estatus del torneo</small>
</h1>
<p-toast></p-toast>
<!-- Contadores -->
<div class="row mb-1">
  <div class="p-col-12 p-xl-6">
    <div class="card shadow">
      <div class="card-body">
        <div class="row">
          <div class="font-weight-bold text-primary text-uppercase mb-1">
            <i class="bi bi-people-fill text-right"></i>
            Jugadores
          </div>
        </div>
        <div class="row">
          <div class="col-4">
            <span class="indicator indicator-equal mdi mdi-chevron-right"></span>
            <h4 class="text-center contador-number">{{contadorJugadores.total}}</h4>
          </div>
          <div class="col-4">
            <h4 class="text-center contador-number">{{contadorJugadores.confirmados}}</h4>
          </div>
          <div class="col-4">
            <h4 class="text-center contador-number">{{contadorJugadores.sinConfirmar}}</h4>
          </div>
        </div>
        <div class="row">
          <div class="col-4">
            <div class="data-tile text-center">Total</div>
          </div>
          <div class="col-4">
            <div class="data-tile text-center">Confirmados</div>
          </div>
          <div class="col-4">
            <div class="data-tile text-center">Sin confirmar</div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="p-col-12 p-xl-6 ">
    <div class="card shadow">
      <div class="card-body">
        <div class="row">
          <div class="font-weight-bold text-primary text-uppercase mb-1">
            <i class="bi bi-flag-fill"></i>
            Foursome
          </div>
        </div>
        <div class="row">
          <div class="col-4">
            <span class="indicator indicator-equal mdi mdi-chevron-right"></span>
            <h3 class="text-center contador-number">{{equiposContadores.completo }}</h3>
          </div>
          <div class="col-4">
            <h3 class="text-center contador-number">{{equiposContadores.incompletos}}</h3>
          </div>
          <div class="col-4">
            <span class="indicator indicator-equal mdi mdi-chevron-right"></span>
            <h3 class="text-center contador-number">{{equiposContadores.sinEquipo }}</h3>
          </div>
        </div>
        <div class="row">
          <div class="col-4">
            <div class="data-tile text-center" title="Completos">Completos </div>
          </div>
          <div class="col-4">
            <div class="data-tile text-center" title="Incompletos">Incompletos</div>
          </div>
          <div class="col-4">
            <div class="data-tile text-center" title="Sin foursome">Sin foursome </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="row mb-4">
  <div class="p-col-12 p-xl-6">
    <div class="card shadow">
      <div class="card-body">
        <div class="row">
          <div class="font-weight-bold text-primary text-uppercase mb-1">
            <i class="bi bi-currency-dollar"></i> Cobranza de jugadores
          </div>
        </div>
        <div class="row">
          <div class="col-4">
            <span class="indicator indicator-equal mdi mdi-chevron-right"></span>
            <h3 class="text-center contador-number">{{pagoJugadores.alDia|currency }}</h3>
          </div>
          <div class="col-4">
            <h3 class="text-center contador-number">{{pagoJugadores.pagado|currency}}</h3>
          </div>
          <div class="col-4">
            <h3 class="text-center contador-number">{{pagoJugadores.saldo|currency}}</h3>
          </div>
        </div>
        <div class="row">
          <div class="col-4">
            <div class="data-tile text-center" title="Completos">Del día </div>
          </div>
          <div class="col-4">
            <div class="data-tile text-center" title="Incompletos">Total</div>
          </div>
          <div class="col-4">
            <div class="data-tile text-center" title="Incompletos">Pendiente</div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="p-col-12 p-xl-6">
    <div class="card shadow">
      <div class="card-body">
        <div class="row">
          <div class="font-weight-bold text-primary text-uppercase mb-1">
            <i class="bi bi-currency-dollar"></i>Cobranza de patrocinadores
          </div>
        </div>
        <div class="row">
          <div class="col-4">
            <span class="indicator indicator-equal mdi mdi-chevron-right"></span>
            <h3 class="text-center contador-number">{{pagoPatrocinadores.alDia|currency }}</h3>
          </div>
          <div class="col-4">
            <h3 class="text-center contador-number">{{pagoPatrocinadores.pagado|currency}}</h3>
          </div>
          <div class="col-4">
            <h3 class="text-center contador-number">{{pagoPatrocinadores.saldo|currency}}</h3>
          </div>
        </div>
        <div class="row">
          <div class="col-4">
            <div class="data-tile text-center" title="Completos">Del día</div>
          </div>
          <div class="col-4">
            <div class="data-tile text-center" title="Incompletos">Total</div>
          </div>
          <div class="col-4">
            <div class="data-tile text-center" title="Incompletos">Pendiente</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Tablas -->
<div class="row mb-4">
  <!--Jungadores sin confirmar -->
  <div class="p-col-12 p-md-12 p-lg-7">
    <p-table #dt [value]="jugadoresSinConfirmar" [rows]="6" [paginator]="true"
             responsiveLayout="scroll"
             [rowHover]="true"
             dataKey="id"
             [showCurrentPageReport]="true"
             currentPageReportTemplate="Muestra {first} al {last}  de {totalRecords} jugadores"
             styleClass="p-datatable-gridlines"
             [globalFilterFields]="['nombreCompleto','foursome','inscripcion','pago', 'equipo']"
             sortField="fechaCreacion" sortOrder="-1">
      <ng-template pTemplate="caption">
        <p-toolbar>
          <ng-template pTemplate="left">
            <h4 class="m-0 font-weight-bold text-primary">Jugadores sin check in</h4>
          </ng-template>
          <ng-template pTemplate="right">
            <span class="p-input-icon-left">
              <i class="pi pi-search"></i>
              <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')" placeholder="Buscar..." />
            </span>
          </ng-template>
        </p-toolbar>
      </ng-template>
      <ng-template pTemplate="header">
        <tr>
          <th pSortableColumn="nombre">Jugador <p-sortIcon field="nombre, apellidos"></p-sortIcon></th>
          <th pSortableColumn="equipo">Foursome <p-sortIcon field="equipo"></p-sortIcon></th>
          <th>Inscripción</th>
          <th pSortableColumn="pago">Pagos <p-sortIcon field="pago"></p-sortIcon></th>
          <th pSortableColumn="saldo">Saldo<p-sortIcon field="saldo"></p-sortIcon></th>
          <th></th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-jugador>
        <tr>
          <td>{{ jugador.nombreCompleto}}</td>
          <td>{{ jugador.equipo }}</td>
          <td>{{ jugador.precioInscripcion | currency }}</td>
          <td>{{ jugador.pago | currency}}</td>
          <td>{{ jugador.saldo | currency}}</td>
          <td class="text-center">
            <button pButton pRipple icon="pi pi-check" class="p-button-info p-mr-2" label="Check in" (click)="openDialog(jugador)"></button>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
  <!-- Equipos -->
  <div class="p-col-12 p-md-12 p-lg-5" style="padding-top: -0.5rem !important;">
    <p-table #dq [value]="equipos" [rows]="6" [paginator]="true"
             responsiveLayout="scroll"
             [rowHover]="true"
             dataKey="id"
             [showCurrentPageReport]="true"
             [resizableColumns]="true"
             currentPageReportTemplate="Muestra {first} al {last} de {totalRecords} equipos"
             styleClass="p-datatable-gridlines"
             [globalFilterFields]="['equipoid','descripcion', 'completo']">
      <ng-template pTemplate="caption">
        <p-toolbar>
          <ng-template pTemplate="left">
            <h4 class="m-0 font-weight-bold text-primary">Equipos</h4>
          </ng-template>
          <ng-template pTemplate="right">
            <span class="p-input-icon-left">
              <i class="pi pi-search"></i>
              <input pInputText type="text" (input)="dq.filterGlobal($event.target.value, 'contains')" placeholder="Buscar..." />
            </span>
          </ng-template>
        </p-toolbar>
      </ng-template>
      <ng-template pTemplate="header">
        <tr>
          <th>Clave</th>
          <th>Equipo</th>
          <th pSortableColumn="completo">Completo <p-sortIcon field="completo"></p-sortIcon></th>
          <th pSortableColumn="confirmados">Confirmados  <p-sortIcon field="confirmados"></p-sortIcon></th>
          <th>Salida</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-equipo>
        <tr>
          <td>{{ equipo.equipoId }}</td>
          <td>{{ equipo.descripcion }}</td>
          <td class="text-center">
            <div class="badge" [ngClass]="{'bg-success':equipo.completo,'bg-danger':!equipo.completo}">
              {{equipo.completo?'Completo':'Incompleto'}} {{ equipo.jugadoresActuales }}
            </div>
          </td>
          <td class="text-center">{{ equipo.confirmados }}</td>
          <td class="text-center">
            <button pButton class="p-button-info p-mr-2" (click)="openDialogEquipo(equipo)">
              Salida  <span class="badge" [ngClass]="{'badg-normal':equipo.hoyoSalida>0,'bg-danger':equipo.hoyoSalida <=0} " style="margin-left: 10px;">{{equipo.hoyoSalida}}</span>
            </button>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>

<!-- Graficos de Patrocinadores -->
<div class="row">
  <!-- Content Column -->
  <div class="col-md-6 mb-4">
    <!-- Project Card Example -->
    <div class="card shadow">
      <div class="card-header py-3">
        <h4 class="m-0 font-weight-bold text-primary">Patrocinadores por tipo</h4>
      </div>
      <div class="card-body">
        <!--<p-chart type="bar" [data]="barData" [options]="barOptions"></p-chart>-->
        <p-chart type="doughnut" [data]="barData"></p-chart>
      </div>
    </div>
  </div>
  <div class="col-md-6 mb-4">
    <div class="card shadow">
      <div class="card-header py-3">
        <h4 class="m-0 font-weight-bold text-primary">Aportación de patrocinadores</h4>
      </div>
      <div class="card-body" *ngFor="let p of patrocinadorePorcentaje">
        <h4 class="small font-weight-bold">{{p.nombre}} <span _ngcontent-rwp-c56="" class="float-right"></span>{{p.total | currency}}</h4>
        <p-progressBar [value]="p.pagado|number: '1.0-0'"></p-progressBar>
      </div>
    </div>
  </div>

  <div class="col-lg-6 mb-4">
    <!-- Illustrations -->
    <!--<div class="card shadow mb-4">
      <div class="card-header py-3">
        <h6 class="m-0 font-weight-bold text-primary">Jugadores sin equipo</h6>
      </div>
      <div class="card-body">

      </div>
    </div>-->
    <!-- Approach -->
    <div class="card shadow mb-4">
      <div class="card-header py-3">
        <h6 class="m-0 font-weight-bold text-primary">Development Approach</h6>
      </div>
      <div class="card-body">
        <p>
          SB Admin 2 makes extensive use of Bootstrap 4 utility classes in order to reduce
          CSS bloat and poor page performance. Custom CSS classes are used to create
          custom components and custom utility classes.
        </p>
        <p class="mb-0">
          Before working with this theme, you should become familiar with the
          Bootstrap framework, especially the utility classes.
        </p>
      </div>
    </div>
    <div class="card-body">
      <h6>Users from secure api end point</h6>
      <div *ngIf="loading" class="spinner-border spinner-border-sm"></div>
      <ul *ngIf="users">
        <li *ngFor="let user of users">{{user.nombre}} {{user.correo}}</li>
      </ul>
    </div>

  </div>
</div>

<p-dialog [(visible)]="jugadorDialog"
          [style]="{ width: '550px' }"
          class="p-fluid"
          [modal]="false">
  <p-header>{{jugador.nombre}} {{jugador.apellidos}}</p-header>
  <ng-template pTemplate="content">
    <p-tabView styleClass="tabview-custom">
      <p-tabPanel styleClass="tabview-custom">
        <ng-template pTemplate="header">
          <i class="bi bi-check-square" style="margin-right: 5px"></i>
          <span>Confirmar</span>
        </ng-template>
        <div class="p-formgrid p-grid">
          <div class="p-field p-col">
            <label for="equipoId">Equipo</label>
            <p-dropdown [options]="equiposDisponibles" [(ngModel)]="jugador.equipoId" optionLabel="descripcion"
                        placeholder="Seleccionar..." optionValue="equipoId" [showClear]="true" [disabled]="equipoView" required>
              <ng-template let-equipo pTemplate="item">
                <div class="equipo-items">{{equipo.descripcion}}</div>
              </ng-template>
            </p-dropdown>
            <small class="p-error" *ngIf="submitted && !jugador.equipoId">El equipo es requerido.</small>
          </div>
        </div>
        <div class="p-formgrid p-grid">
          <div class="p-field-checkbox p-col" style="margin-top: 25px;">
            <p-checkbox name="confirmado" [(ngModel)]="jugador.confirmado" binary="true" inputId="confirmado" label="Check in" required></p-checkbox>
            <samll class="p-error" *ngIf="submitted && !jugador.confirmado"> Es requerido.</samll>
          </div>
        </div>
      </p-tabPanel>
      <p-tabPanel styleClass="tabview-custom" *ngIf="jugador.saldo>0">
        <ng-template pTemplate="header">
          <i class="bi bi-currency-dollar" style="margin-right: 5px"></i>
          <span>Pagos</span>
        </ng-template>
        <div class="p-formgrid p-grid">
          <div class="p-field p-col">
            <label for="aportacion">Precio inscripción</label>
            <p-inputNumber [(ngModel)]="jugador.precioInscripcion"
                           [min]="0"
                           mode="decimal"
                           [minFractionDigits]="2"
                           locale="en-US"
                           disabled="true"></p-inputNumber>
          </div>
          <div class="p-field p-col">
            <label for="pagado">Pagado</label>
            <p-inputNumber [(ngModel)]="jugador.pago"
                           [min]="0"
                           mode="decimal"
                           [minFractionDigits]="2"
                           locale="en-US"
                           disabled="true"></p-inputNumber>
          </div>
          <div class="p-field p-col">
            <label for="saldo">Saldo</label>
            <p-inputNumber [(ngModel)]="jugador.saldo"
                           [min]="0"
                           mode="decimal"
                           [minFractionDigits]="2"
                           locale="en-US"
                           disabled="true"></p-inputNumber>
          </div>
        </div>
        <app-pagos [jugadorId]="jugador.jugadorId" [saldo]="jugador.saldo" (onPagosChanged)="onPagosChanged($event)"></app-pagos>
      </p-tabPanel>
    </p-tabView>
  </ng-template>
  <ng-template pTemplate="footer">
    <button pButton pRipple label="Cancelar" icon="pi pi-times" class="p-button-secondary" (click)="hideDialog()"></button>
    <button pButton pRipple label="Guardar" icon="pi pi-check" (click)="guardarJugador()"></button>
  </ng-template>
</p-dialog>

<p-dialog [(visible)]="equipoDialog"
          [style]="{ width: '550px' }"
          class="p-fluid"
          [modal]="false">
  <p-header>
    {{equipo.descripcion}} <span class="badge" [ngClass]="{'bg-success':equipo.completo,'bg-danger':!equipo.completo}">{{equipo.completo ?'Completo':'Incompleto'}}</span>
  </p-header>
  <ng-template pTemplate="content">
    <div class="p-formgrid p-grid">
      <div class="p-field p-col">
        <label for="aportacion">Jugadores actuales</label>
        <p-inputNumber [(ngModel)]="equipo.jugadoresActuales" [min]="0" mode="decimal" disabled="true"></p-inputNumber>
      </div>
      <div class="p-field p-col">
        <label for="hoyoSalida">Hoyo de salida</label>
        <p-inputNumber [(ngModel)]="equipo.hoyoSalida" [showButtons]="true" mode="decimal" [min]="1" [max]="18" inputId="hoyoSalida"></p-inputNumber>
        <small class="p-error" *ngIf="submitted && !equipo.hoyoSalida>0">El hoyo de salida es requerido.</small>
      </div>
      <div class="p-field p-col">
        <label for="turnoSalida">Turno</label>
        <p-selectButton [options]="stateOptions" [(ngModel)]="equipo.turnoSalida" optionLabel="label" optionValue="value"></p-selectButton>
      </div>
    </div>
  </ng-template>
  <ng-template pTemplate="footer">
    <button pButton pRipple label="Cancelar" icon="pi pi-times" class="p-button-secondary" (click)="hideDialogEquipo()"></button>
    <button pButton pRipple label="Guardar" icon="pi pi-check" (click)="guardarEquipo(equipo)"></button>
  </ng-template>
</p-dialog>

<p-confirmDialog class="my-confirm-dialog" [style]="{width: '450px'}" baseZIndex="10000" #cd>
  <p-footer>
    <button pButton pRipple label="No" icon="pi pi-times" class="p-button-secondary" (click)="cd.reject()"></button>
    <button pButton pRipple icon="pi pi-check" label="Si" (click)="cd.accept()"></button>
  </p-footer>
</p-confirmDialog>

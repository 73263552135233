import { NgModule } from '@angular/core';
import { TableModule } from 'primeng/table';
import { ToastModule } from 'primeng/toast';
import { ToolbarModule } from 'primeng/toolbar';
import { DialogModule } from 'primeng/dialog';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ButtonModule } from 'primeng/button';
import { TooltipModule } from 'primeng/tooltip';
import { RadioButtonModule } from 'primeng/radiobutton';
import { AutoCompleteModule } from 'primeng/autocomplete';
import {InputTextareaModule} from 'primeng/inputtextarea';
import { InputSwitchModule } from 'primeng/inputswitch';
import { InputTextModule } from 'primeng/inputtext';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputMaskModule } from 'primeng/inputmask';
import { CalendarModule} from 'primeng/calendar';
import { RatingModule } from 'primeng/rating';
import { DropdownModule } from 'primeng/dropdown';
import { CheckboxModule } from 'primeng/checkbox';
import { TabViewModule } from 'primeng/tabview';
import { ProgressSpinnerModule } from 'primeng/progressspinner'
import { MultiSelectModule } from 'primeng/multiselect'
import { ChartModule } from 'primeng/chart'
import { ProgressBarModule } from 'primeng/progressbar'
import { SelectButtonModule } from 'primeng/selectbutton';

@NgModule({
  declarations: [],
  exports: [
    CalendarModule,
    DialogModule,
    ConfirmDialogModule,
    TableModule,
    ToastModule,
    ToolbarModule,
    ButtonModule,
    RadioButtonModule,
    InputSwitchModule,
    TooltipModule,
    InputTextModule,
    InputTextareaModule,
    InputNumberModule,
    InputMaskModule,
    RatingModule,
    DropdownModule,
    CheckboxModule,
    TabViewModule,
    ProgressSpinnerModule,
    MultiSelectModule,
    ChartModule,
    ProgressBarModule,
    SelectButtonModule

  ]
})
export class PrimengModule { }

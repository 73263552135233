import { Component, OnInit } from '@angular/core';
import { MessageService } from 'primeng/api';
import { first } from 'rxjs/operators';
import { Equipo, User } from '../_models';
import { Contador } from '../_models/contador';
import { DashboardPatrocinadorTipo } from '../_models/dashboardPatrocinadorTipo';
import { DataResult } from '../_models/dataResult';
import { Jugador } from '../_models/jugador';
import { JugadorPago } from '../_models/jugadorPago';
import { UserService } from '../_services';
import { DashboardService } from '../_services/dashboard.service';
import { EquipoService } from '../_services/equipos.service';
import { JugadorService } from '../_services/jugador.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.less'],
  providers: [MessageService]
})
export class DashboardComponent implements OnInit {
  loading = false;
  users: User[];
  contadores: Contador[];
  jugadoresSinConfirmar: Jugador[];
  jugador: Jugador;
  jugadorPago: JugadorPago; 
  equipos: Equipo[];
  equiposDisponibles: Equipo[]; 
  equipo: Equipo;
  patrocinadoresTipo: DashboardPatrocinadorTipo[]; 
  contadorJugadores: any = { total: 0, confirmados: 0, sinConfirmar: 0 };
  equiposContadores: any = { completo: 0, incompletos: 0, sinEquipo: 0 };
  pagoJugadores: any = { alDia: 0.0, pagado: 0.0, saldo: 0.0 };
  pagoPatrocinadores: any = { alDia: 0.0, pagado: 0.0, saldo: 0.0 };
  patrocinadorePorcentaje: any[];
  jugadorDialog: boolean;
  barData: any;
  barOptions: any;
  submitted: boolean;
  mostrarEquipo: boolean;
  equipoDialog: boolean;
  equipoView: boolean;
  result: DataResult;
  constructor(
    private service: DashboardService,
    private messageService: MessageService,
    private userService: UserService,
    private jugadoresServices: JugadorService,
    private equipoServices: EquipoService) { }
  ngOnInit(): void {
    this.loading = true;
    this.jugador = { ...this.jugadorEmpty }
    this.equipo = { ...this.equipoEmpty };
    this.userService.getAll().pipe(first()).subscribe(users => {
      this.loading = false;
      this.users = users;
    });
    this.relodContadores();
    this.relodJugadores();
    this.reloadEquipos();

    this.service.getPatrocinadores().subscribe(
      r => {
        this.crearDatosPatrocinadores(r); 
      },
      (e) => {

      }
    );
  }

  crearDatosPatrocinadores(patrocinadoresTipo: DashboardPatrocinadorTipo[])
  {
    let tipos = patrocinadoresTipo.map(x => x.descripcion);
    let valores = patrocinadoresTipo.map(x => x.cantidad);
    this.patrocinadorePorcentaje= patrocinadoresTipo.map(function (item) {
      return {
        id: item.tipoId,
        nombre: item.descripcion,
        total: item.valor,
        pagado: (100/item.valor) * item.pagado
      }
    });

    this.barData = {
      labels: tipos,
      datasets: [
        {
          data: valores,
          backgroundColor: ["#F7464A", "#46BFBD", "#FDB45C", '#EC407A', '#AB47BC', '#42A5F5', '#7E57C2', '#66BB6A', '#FFCA28', '#26A69A']
        }
      ],
    };

    this.barOptions = {
      legend: {
        position: 'right',
      }
    }; 
  }
  relodJugadores() {
    this.jugadoresServices.getAll().subscribe(
      (r) => {

        this.jugadoresSinConfirmar = r.filter(x => x.confirmado === false && x.eliminado === false);
      },
      (e) => {
      }
    );
  }
  relodContadores() {
    this.service.getAll().subscribe(
      (result) => {
        this.contadores = result;
        //------------------------Contadores ------------------------
        //Jugadores totales y confirmados
        this.contadorJugadores.total = this.contadores.find(x => x.id === 1).totales;
        this.contadorJugadores.confirmados = this.contadores.find(x => x.id === 2).totales;
        this.contadorJugadores.sinConfirmar = this.contadores.find(x => x.id === 3).totales;

        //Equipos o forusome
        this.equiposContadores.sinEquipo = this.contadores.find(x => x.id === 4).totales;
        this.equiposContadores.completo = this.contadores.find(x => x.id === 5).totales;
        this.equiposContadores.incompletos = this.contadores.find(x => x.id === 6).totales;

        //Pagos jugadores
        this.pagoJugadores.alDia = this.contadores.find(x => x.id === 8).totales;
        this.pagoJugadores.pagado = this.contadores.find(x => x.id === 9).totales;
        this.pagoJugadores.saldo = this.contadores.find(x => x.id === 10).totales;

        //Patrocinadores
        this.pagoPatrocinadores.alDia = this.contadores.find(x => x.id === 11).totales;
        this.pagoPatrocinadores.pagado = this.contadores.find(x => x.id === 12).totales;
        this.pagoPatrocinadores.saldo = this.contadores.find(x => x.id === 13).totales;

      },
      (err) => {
      }
    );
  }
  reloadEquipos() {
    this.equipoServices.getAll().subscribe(
      (r) => {
        this.equipos = r;
      },
      (e) => {
      }
    );
  }

  //-----------------Jugador-------------------
  openDialog(jugador: Jugador) {
    this.jugadorDialog = true;
    this.jugador = { ...jugador };
    this.submitted = false;

    if (!jugador.equipoId) {
      this.equipoView = false;
      this.equipoDisponibles(false);
    }
    else {
      this.equipoView = true;
      this.equipoDisponibles(true);
    }
  }
  hideDialog() {
    this.jugadorDialog = false;
    this.jugador = { ...this.jugadorEmpty };
  }
  async guardarJugador()
  {
    this.submitted = true;
    
    if (this.jugador.saldo > 0) {
      this.messageService.add({ severity: 'warn', summary: '¡Aviso!', detail: 'No se puede confirmar un jugador con saldo pendiente', life: 3000 });
      return;
    }
    if (this.jugador.equipoId == null) {
      this.messageService.add({ severity: 'warn', summary: '¡Aviso!', detail: 'No se puede confirmar un jugador sin equipo', life: 3000 });
      return;
    }

    if (this.jugador.coutaId === 4 && this.jugador.equipoId > 0)
    {
      var x  = await this.jugadoresServices.getPuedeConfirmar(this.jugador.jugadorId).toPromise();
      if(!x.success)
      {
        this.messageService.add({ severity: 'warn', summary: '¡Aviso!', detail: x.message, life: 3000 });
        return;
      }  
    }
  
    if (this.jugador.confirmado)
    {
      let jugadorUpdate = { ...this.jugador };
      this.jugadoresServices.update(this.jugador).subscribe(
        (result) => {
          this.actualizarEquipo(jugadorUpdate.equipoId);
          this.jugadoresSinConfirmar = this.jugadoresSinConfirmar.filter(j => j.jugadorId !== this.jugador.jugadorId);

          this.messageService.add({ severity: 'success', summary: '¡Listo!', detail: 'Jugador actualizado', life: 3000 });
          this.relodJugadores();
          this.relodContadores();
        },
        (err) => {
          this.messageService.add({ severity: 'error', summary: '¡Error!', detail: 'Problema al actualizar el registro del jugador', life: 3000 });
        });

      this.jugadorDialog = false;
      this.jugador = { ...this.jugadorEmpty };
      this.mostrarEquipo = false;
    }
  }
  //Método utilizado para recibir eventos del component de pagos
  onPagosChanged(jugadorPago: JugadorPago) {
    //Actualizar datos del jugador que realizaro pago en lista de jugadores.
    this.jugadoresServices.getJugador(jugadorPago.jugadorId).subscribe(
      result => {
        console.debug(result)
        this.jugador = result;
        this.jugadoresSinConfirmar[this.findIndexById(jugadorPago.jugadorId)] = result;
        this.jugadoresSinConfirmar = [...this.jugadoresSinConfirmar];
        this.relodContadores();
      },
      error => {
        this.jugadoresSinConfirmar = [];
        this.messageService.add({ severity: 'error', summary: '¡Error!', detail: 'Problema al obtener el registro del jugador', life: 3000 });

      }
    );
  }

  //-----------------Equipo-------------------
  stateOptions: any[] = [{label: 'A', value: 'A'}, {label: 'B', value: 'B'}];

  
  openDialogEquipo(equipo: Equipo) {
    this.equipoDialog = true;
    this.equipo = { ...equipo };
  }
  hideDialogEquipo() {
    this.equipoDialog = false;
    this.equipo = { ...this.equipoEmpty };
    this.submitted = false; 
  }
  guardarEquipo(equipo: Equipo) {
    this.submitted = true;
    if (equipo.jugadoresActuales < 4) {
      this.messageService.add({ severity: 'warn', summary: '¡Aviso!', detail: 'No se puede asignar un hoyo de salida si el equipo esta incompleto.', life: 3000 });
      return;
    }
    //validacion para confirmar hoyo de salida
    /* Jorge solicito se removiera la validacion
    if (equipo.confirmados != equipo.jugadoresActuales) {
      this.messageService.add({ severity: 'warn', summary: '¡Aviso!', detail: 'No se puede asignar un hoyo de salida si todos los integrantes de equipo no estan confirmados.', life: 3000 });
      return;
    }*/

    if (equipo.hoyoSalida > 0)
    {
      let registroUpdate = { ...this.equipo };

      this.equipoServices.update(this.equipo).subscribe(
        (result) => {
          //Actualizar equipo
          this.equipos[this.findEquipoIndexById(registroUpdate.equipoId)] = registroUpdate;
          this.messageService.add({ severity: 'success', summary: 'Muy bien!', detail: 'Registro de equipo actualizado', life: 3000 });
        },
        (err) => {
          this.equipos = []
          this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Problema al actualizar', life: 3000 });
        }
      );
      this.equipos = [...this.equipos];
      this.equipo = { ...this.equipoEmpty };
      this.equipoDialog = false;
    }
  }
  equipoDisponibles(conEquipo)
  {
    if (conEquipo)
      this.equiposDisponibles = this.equipos.filter(x => x.equipoId === this.jugador.equipoId); 
    else
      this.equiposDisponibles = this.equipos.filter(x => x.jugadoresActuales < 5 && x.completo === false)
  }
  //Actualizar equipo en cuanto a jugadores actuales y completo. 
  actualizarEquipo(equipoId: number)
  {
    if (equipoId !== null)
    {
      this.equipoServices.updateEquipo(equipoId).subscribe(
        (result) => {
          this.equipo = result; 
          this.equipos[this.findEquipoIndexById(equipoId)] = this.equipo;
          this.messageService.add({ severity: 'success', summary: '¡Listo!', detail: 'Se actualizó el equipo', life: 3000 });
          this.reloadEquipos();
        },
        (err) => {
          this.messageService.add({ severity: 'error', summary: '¡Error!', detail: 'Problema al actualizar el equipo', life: 3000 });
        }
      );
    }

    this.equipos = [...this.equipos];
    this.equipo = { ...this.equipoEmpty };
  }
  consultarJugadoresConfirmados(equipoId: number) {

    this.jugadoresServices.getJugadoresConfirmados(equipoId).subscribe(
      (result) => {
        console.debug(result)
      },
      (err) => {
        this.messageService.add({ severity: 'error', summary: '¡Error!', detail: 'Problema al actualizar el registro del jugador', life: 3000 });
      });
  }

  public get jugadorEmpty(): Jugador {
    return {
      jugadorId: '',
      nombre: '',
      apellidos: '',
      telefono: '',
      correo: '',
      coutaId: 0,
      cuota: '',
      equipoId: 0,
      equipo: '',
      confirmado: false,
      requiereRecibo: false,
      referenciaWeb: null,
      reciboNombreFiscal: '',
      reciboRFC: '',
      precioInscripcion: 0,
      pago: 0,
      saldo: 0,
      imageFilenameQR: '',
      patrocinadorId: null,
      patrocinador: '',
      fechaRegistro: new Date(),
      creadoPor: '',
      editadoPor: '',
      fechaCreacion: new Date(),
      eliminado: false,
      fechaEdicion: new Date(),
      fechaEliminado: null,
      handicap:0, 
      reciboEntregado: false
    }
  }
  public get equipoEmpty(): Equipo {
    return {
      equipoId: 0,
      jugadoresActuales: 0,
      fechaRegistro: new Date(),
      descripcion: "",
      completo: false,
      pagado: false,
      referenciaWeb: 0,
      capitanId: null,
      creadoPor: null,
      editadoPor: null,
      fechaCreacion: new Date(),
      fechaEdicion: new Date(),
      fechaEliminado: null,
      eliminado: false,
      jugadores: null,
      hoyoSalida: 0,
      turnoSalida:"",
      confirmados:0
    };
  }
  findIndexById(id: string): number {
    let index = -1;
    for (let i = 0; i < this.jugadoresSinConfirmar.length; i++) {
      if (this.jugadoresSinConfirmar[i].jugadorId === id) {
        index = i;
        break;
      }
    }
    return index;
  }
  findEquipoIndexById(id: number): number {
    let index = -1;
    for (let i = 0; i < this.equipos.length; i++) {
      if (this.equipos[i].equipoId === id) {
        index = i;
        break;
      }
    }
    return index;
  }
}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environments/environment';
import {  Rotario } from '@app/_models';

@Injectable({
  providedIn: 'root'
})
export class RotariosService {

  constructor(private http: HttpClient) { }

  getAll() {
    return this.http.get<Rotario[]>(`${environment.apiUrl}/api/rotarios`);
  }
  create(rotario: Rotario) {
    return this.http.post<Rotario>(`${environment.apiUrl}/api/rotarios`, rotario);
  }
  update(rotario: Rotario) {
    return this.http.put(`${environment.apiUrl}/api/rotarios/${rotario.rotarioId}`, rotario);
  }
  delete(rotarioId: number) {
    return this.http.delete(`${environment.apiUrl}/api/rotarios/${rotarioId}`);
  }

}

  <!-- Sidebar -->
<div class="bg-light border-right" id="sidebar-wrapper" [ngClass]="status ? 'hide' : 'show'">
  <div class="sidebar-heading text-center">
    <a routerLink="/" class="text-decoration-none">
      <!-- <h1 class="logo fs-4"> <i class="bi bi-flag-fill"></i> Acttiva Golf</h1> -->
      <img style="height: 70px;" src="./assets/components/images/rotarios-jurica.png">
    </a>
  </div>
  <hr>
  <ul class="nav nav-pills list-group list-group-flush">
    <li class="list-group-item list-group-item-action">
      <a routerLink="/dashboard"
         routerLinkActive="active" 
         class="nav-link link-dark" aria-current="page">
        <i class="bi bi-speedometer"></i>
        Dashboard
      </a>
    </li>
    
    <li class="list-group-item list-group-item-action">
      <a routerLink="/patrocinadores"
         routerLinkActive="active"
         class="nav-link link-dark">
        <i class="bi bi-award-fill"></i>
        Patrocinadores
      </a>
    </li>

    <li class="list-group-item list-group-item-action">
      <a routerLink="/jugadores"
         routerLinkActive="active"
         routerLinkActiveOptions="{exact:false}"
         class="nav-link link-dark">
        <i class="bi bi-person-fill"></i>
        Jugadores
      </a>
    </li>
    <li class="list-group-item list-group-item-action"  >
      <a (click)="toggleAdmin()"
         routerLinkActive="active"
         
         class="nav-link link-dark">
        <i class="bi bi-gear-fill"></i>
          Administración  
          <i class="bi" [ngClass]="adminHide ? 'bi-chevron-down' : 'bi-chevron-up'"></i>
      </a>
      <ul [@toogle]="adminState" class="nav nav-pills list-group-flush overflow-hidden"> 
        <li class="list-group-item list-group-item-action">
          <a routerLink="/rotarios"
          routerLinkActive="active"
             class="nav-link link-dark">
            <i class="bi bi-stars"></i>
            Rotarios
          </a>
        </li>      
        <li class="list-group-item list-group-item-action">
          <a routerLink="administracion/patrocinador-tipo"
            routerLinkActive="active"
             class="nav-link link-dark">
            <i class="bi bi-award-fill"></i>
             Tipos de patrocinador
          </a>
        </li>
        <li class="list-group-item list-group-item-action">
          <a routerLink="administracion/cuotas"
            routerLinkActive="active"
             class="nav-link link-dark">
            <i class="bi bi-currency-dollar"></i>
             Cuotas por jugador
          </a>
        </li>
        <li class="list-group-item list-group-item-action">
          <a routerLink="administracion/equipos"
            routerLinkActive="active"
             class="nav-link link-dark">
            <i class="bi bi-award-fill"></i>
            Equipos
          </a>
        </li>
      </ul>
    </li>   
  </ul>
  
  <br>
  <hr>
  
  <div class="my-5 text-center">
  <img style="height: 120px;" src="./assets/components/images/torneo.png">
  </div>
</div>

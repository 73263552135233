import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environments/environment';
import { Patrocinador, PatrocinadorPago} from '@app/_models';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PatrocinadorService {
  constructor(private http: HttpClient) {

     console.log('servicio de patrocinador inicializado');
     
   }

  getAllPatrocinadores() {
    return this.http.get<Patrocinador[]>(`${environment.apiUrl}/api/patrocinadores`);
  }
  getPatrocinador(patrocinadorId: string) {
    return this.http.get<Patrocinador>(`${environment.apiUrl}/api/patrocinadores/${patrocinadorId}`);
  }

  //Insertat un patrocinador
  createPatrocinador(patrocinador: Patrocinador) {
    return this.http.post<Patrocinador>(`${environment.apiUrl}/api/patrocinadores`, patrocinador);
  }

  update(patrocinador: Patrocinador) {
    return this.http.put(`${environment.apiUrl}/api/patrocinadores/${patrocinador.patrocinadorId}`, patrocinador);
  }

  delete(patrocinadorId: string) {
    return this.http.delete(`${environment.apiUrl}/api/patrocinadores/${patrocinadorId}`);
  }

  //pagos de patrocinadores
  getAllPagos(patrocinadorId : string) {
    return this.http.get<PatrocinadorPago[]>(`${environment.apiUrl}/api/patrocinadorpagos/${patrocinadorId}`);
  }

  createPago(patrocinadorPago: PatrocinadorPago) {
    return this.http.post<PatrocinadorPago>(`${environment.apiUrl}/api/patrocinadorpagos`, patrocinadorPago);
  }

  deletePago(patrocinadorPagoId: number) {
    return this.http.delete(`${environment.apiUrl}/api/patrocinadorpagos/${patrocinadorPagoId}`);
  }

}

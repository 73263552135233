import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environments/environment';
import { Jugador } from '../_models/jugador';
import { Equipo } from '../_models/equipo';
import { JugadorPago } from '../_models/jugadorPago';
import { DataResult } from '../_models/dataResult';

@Injectable({
  providedIn: 'root'
})
export class JugadorService {

  constructor(private http: HttpClient) {
    console.log('servicio de jugadores inicializado');
  }

  getAll(){
    return this.http.get<Jugador[]>(`${environment.apiUrl}/api/jugadores`);
  } 
  getAllCapitanes(){
    return this.http.get<Jugador[]>(`${environment.apiUrl}/api/jugadores/capitanes`);
  }
  getJugador(jugadorId: string) {
    return this.http.get<Jugador>(`${environment.apiUrl}/api/jugadores/${jugadorId}`);
  }
  //Insertar un jugador
  createJugador(jugador: Jugador) {
    return this.http.post<Jugador>(`${environment.apiUrl}/api/jugadores`, jugador);
  }
  //Actualizar un jugador
  update(jugador: Jugador) {
    return this.http.put(`${environment.apiUrl}/api/jugadores/${jugador.jugadorId}`, jugador);
  }

   // Importar equipos con sus jugadores.
  importEquipos() {
    var importData = {
      ImportarDesde: "2020-01-01",
      ValidarExistentes: true
    }
    return this.http.post<Equipo[]>(`${environment.apiUrl}/api/importEquipos`, importData);
  }

  // Importar jugadores individuales
  importJugadoresIndividuales() {
    var importData = {
      ImportarDesde: "2020-01-01",
      ValidarExistentes: true
    }
    return this.http.post<Jugador[]>(`${environment.apiUrl}/api/importJugadores`, importData);
  }

  //Pagos de jugadores
  getAllPagos(jugadorId: string) {
    return this.http.get<JugadorPago[]>(`${environment.apiUrl}/api/jugadorpago/${jugadorId}`);
  }

  createPago(jugadorPago: JugadorPago) {
    return this.http.post<JugadorPago>(`${environment.apiUrl}/api/jugadorpago`, jugadorPago);
  }

  updatePago(jugadorPago: JugadorPago) {
    return this.http.put<JugadorPago>(`${environment.apiUrl}/api/jugadorpago/${jugadorPago.jugadorPagoId}`, jugadorPago);
  }

  deletePago(jugadorPagoId: number) {
    return this.http.delete(`${environment.apiUrl}/api/jugadorpago/${jugadorPagoId}`);
  }


  //No funciona
  getJugadoresConfirmados(equipoId: number) {
    return this.http.get<DataResult>(`${environment.apiUrl}/api/jugadores/confirmados/${equipoId}`)
  }

  getPuedeConfirmar(jugadorId: string) {
    return this.http.get<DataResult>(`${environment.apiUrl}/api/jugadores/puedeconfirmar/${jugadorId}`)
  }
}

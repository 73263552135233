<main>
  <div class="container" >
    <header class="d-flex justify-content-between pb-3">
      <div class="">
        <!-- <h1 class="logo fs-3"> <i class="bi bi-flag-fill"></i> Acttiva Golf</h1> -->
        <img style="height: 100px;" src="./assets/components/images/rotarios-jurica.png">
        
      </div>     
      <div class="">
        <img style="height: 130px;" src="./assets/components/images/torneo.png">
      </div>    
    </header>

    <div class="p-5 mb-4 bg-light rounded-3">
      <div class="container-fluid py-4">
        <h1 class="display-5 fw-bold">Bienvenido</h1>
        <p class="col-md-8 fs-4">Hola, te damos la bienvenida al aplicativo para el torneo de golf del club rotario Jurica. 
          Pulsa acceder para entrar
        </p>
        <a class="btn btn-primary btn-lg" routerLink="/dashboard"  type="button">Acceder</a>
      </div>
    </div>

    

    <footer class="pt-3 mt-4 border-top fw-bold" style="color:#fff">
      © {{currentYear}} powered by <a class="text-reset" href="https://expertnetconsulting.net">Expertnet Consulting</a> and <a class="text-reset" href="https://crmacttiva.com">CRM Acttiva</a>
      <span> Versión 1.0.202212.1</span> 
    </footer>
  </div>
</main>
<table class="table table-sm" #tablePagos>
  <thead>
    <tr>
      <th scope="col" style="width:5%;">#</th>
      <th scope="col" style="width: 25%;">Fecha</th>
      <th scope="col" style="width:25%;">Importe</th>
      <th scope="col" class="text-center" style="width: 35%;">Referencia</th>
      <th scope="col" style="width:5%;"></th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let pago of pagos; let i = index" [attr.data-index]="i" class="animate__animated animate__bounceInDown">
      <th scope="row">{{i+1}}</th>
      <td>{{pago.fecha | date:'dd/MM/yyyy'}}</td>
      <td> {{pago.importe | currency}}</td>
      <td>{{pago.referencia}}</td>
      <td><button pButton pRipple icon="pi pi-trash" class="p-button-rounded p-button-danger" (click)="eliminarPago(pago)"></button></td>
    </tr>
    <tr *ngIf="saldo>0">
      <th scope="row"></th>
      <td>
        <p-calendar [(ngModel)]="pago.fecha" dateFormat="dd/mm/yy" required></p-calendar>
        <small class="p-error" *ngIf="submitted && !pago.fecha">Seleccione una fecha.</small>
      </td>
      <td>
        <p-inputNumber [inputStyle]="{'text-align': 'right'}" [(ngModel)]="pago.importe" [style]="{width: '100%'}"
                       name="importe" [min]="0" mode="decimal" [minFractionDigits]="2"
                       locale="en-US" required></p-inputNumber>
        <small class="p-error" *ngIf="submitted && !pago.importe">Ingrese un importe.</small>
      </td>
      <td>
        <input pInputText type="text" name="referencia" placeholder="referencia de pago" [(ngModel)]="pago.referencia" id="referencia">
      </td>
      <td>
        <button pButton pRipple icon="pi pi-plus-circle" class="p-button-rounded p-button-success" (click)="agregarPago()"></button>
      </td>
    </tr>
  </tbody>
</table>


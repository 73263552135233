import { Component, OnInit } from '@angular/core';
import { PrimeNGConfig } from 'primeng/api';

import { AuthenticationService } from './_services';
import { User } from './_models';

@Component({ selector: 'app', templateUrl: 'app.component.html' })
export class AppComponent implements OnInit {
    user: User;

  constructor(private authenticationService: AuthenticationService, private primengConfig: PrimeNGConfig) {
        this.authenticationService.user.subscribe(x => this.user = x);
    }
    ngOnInit(): void {
      this.primengConfig.ripple = true;
    }
    statusSideMenu: boolean;
    clickEvent(){
      this.statusSideMenu = !this.statusSideMenu;       
    }

    
}


    <div class="login">

    
    <div class="form-signin">
        
        <div class="p-4">  
            <div class="text-center pb-4">
                <img style="height: 70px;" src="./assets/components/images/rotarios-jurica.png">
            </div>
           
            <h1 class="h4 pb-2 " >Iniciar sesión</h1>            
            <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
              <div class="form-group">
                <label for="username">Correo</label>
                <input type="text" formControlName="username" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.username.errors }" />
                <div *ngIf="submitted && f.username.errors" class="invalid-feedback">
                  <div *ngIf="f.username.errors.required">Username is required</div>
                </div>
              </div>
              <div class="form-group">
                <label for="password">Password</label>
                <input type="password" formControlName="password" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.password.errors }" />
                <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                  <div *ngIf="f.password.errors.required">Password is required</div>
                </div>
              </div>
              <div class="form-group">
                <label><input type="checkbox" formControlName="rememberMe"> Recordar mis datos</label>
              </div>
              <div class="mt-3 text-center">
                <button [disabled]="loading" class="btn btn-primary" style="width:100%">
                  <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                  Iniciar sesión
                </button>
              </div>
              <div *ngIf="error" class="alert alert-danger mt-3 mb-0">{{error}}</div>
            </form>
        </div>
    </div>
</div>

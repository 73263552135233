import { Component, OnInit } from '@angular/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { RotariosService } from '@app/_services/rotarios.service';
import { Rotario } from '../_models';

@Component({
  selector: 'app-rotarios',
  templateUrl: './rotarios.component.html',
  styleUrls: ['./rotarios.component.less'],
  styles: [`
        :host ::ng-deep .p-dialog .product-image {
            width: 150px;
            margin: 0 auto 2rem auto;
            display: block;
        }
    `],
  providers: [MessageService, ConfirmationService]
})
export class RotariosComponent implements OnInit {


  productDialog: boolean;

  //rotarios: Product[];
  rotarios: Rotario[];

  rotario: Rotario;

  selectedRotarios: Rotario[];

  rotarioEmpty: Rotario = {
    rotarioId: 0, nombre: '',
    apellidos: '', telefono: '',
    correo: '', creadoPor: '', editadoPor: '',
    fechaCreacion: new Date(),
    fechaEdicion: new Date()};

  submitted: boolean;

  statuses: any[];

  constructor(private rotariosService: RotariosService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService) { }

  ngOnInit() {
    //this.rotarioservice.getrotarios().then(data => this.rotarios = data);
    this.rotariosService.getAll().subscribe(
      (result) => {

        this.rotarios = result
      },
      (err) => {
        this.rotarios = []
        //this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'rotarios Deleted', life: 3000 });

      });


   
  }

  openNew() {
    this.rotario = {
      rotarioId: 0, nombre: '',
      apellidos: '', telefono: '',
      correo: '', creadoPor: '', editadoPor: '',
      fechaCreacion: new Date(),
      fechaEdicion: new Date()
    };
    this.submitted = false;
    this.productDialog = true;
  }

  deleteSelectedrotarios() {
    this.confirmationService.confirm({
      message: 'Are you sure you want to delete the selected rotarios?',
      header: 'Confirm',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.rotarios = this.rotarios.filter(val => !this.selectedRotarios.includes(val));
        this.selectedRotarios = null;
        this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'rotarios Deleted', life: 3000 });
      }
    });
  }

  edit(rotario: Rotario) {

    this.rotario = { ...rotario };
    this.productDialog = true;
  }

  delete(rotario: Rotario) {
    this.confirmationService.confirm({
      message: 'Deseas eliminar a ' + rotario.nombre + '?',
      header: 'Confirm',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {

        this.rotariosService.delete(rotario.rotarioId).subscribe(
          (result)=>{

            this.rotarios = this.rotarios.filter(val => val.rotarioId !== rotario.rotarioId);
            this.rotario = {
              rotarioId: 0, nombre: '',
              apellidos: '', telefono: '',
              correo: '', creadoPor: '', editadoPor: '',
              fechaCreacion: new Date(),
              fechaEdicion: new Date() 
            };
            this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'Rotario Eliminado', life: 3000 });

          },
          (err)=>{

            this.messageService.add({ severity: 'error', summary: 'Error', detail: 'No se pudo eliminar', life: 3000 });

          }
        );

       
      }
    });
  }

  hideDialog() {
    this.productDialog = false;
    this.submitted = false;
  }

  save() {
    this.submitted = true;

    if (this.rotario.nombre.trim())
    {      
      if (this.rotario.rotarioId > 0) {
        
        //obtener copia para saber a quien actualizar, al momento del result
        let rotarioUpdate ={...this.rotario};

        //actualizar rotario
        this.rotariosService.update(this.rotario).subscribe(
          (result) =>
          {       

            this.rotarios[this.findIndexById(rotarioUpdate.rotarioId)] = rotarioUpdate; 
            this.messageService.add({ severity: 'success', summary: 'Muy bien!', detail: 'Rotario actualizado', life: 3000 });

          },
          (err) => {
            this.rotarios = []
            this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Problema al actualizar', life: 3000 });
            console.log(err);
            
          });

        
        
      }
      else {
        this.rotario.rotarioId = 0;

        //crear rotario
        this.rotariosService.create(this.rotario).subscribe(
          (result) =>
          {
            this.rotario = result;
            this.messageService.add({ severity: 'success', summary: 'Muy bien!', detail: 'Rotario creado', life: 3000 });
            this.rotarios.push(this.rotario);
          },
          (err) => {
            this.rotarios = []
            //this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'rotarios Deleted', life: 3000 });

          });

        

      }

      this.rotarios = [...this.rotarios];
      this.productDialog = false;
      this.rotario = { ...this.rotarioEmpty };
    }
  }

  findIndexById(id: number): number {
    let index = -1;
    console.log(this.rotarios);
    
    for (let i = 0; i < this.rotarios.length; i++) {
      if (this.rotarios[i].rotarioId === id) {
        index = i;        
        break;
      }
    }

    return index;
  }

  createId(): string {
    let id = '';
    var chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    for (var i = 0; i < 5; i++) {
      id += chars.charAt(Math.floor(Math.random() * chars.length));
    }
    return id;
  }

}
